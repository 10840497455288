<script setup lang="ts">
const props = defineProps<{
  options: { key: string; label: string }[];
  selectedOptionClass?: string;
}>();
const emit = defineEmits<{
  (event: "selected", value: string): void;
}>();
const selected = defineModel("selected");
const handleSelected = (key: string) => {
  selected.value = key;
  emit("selected", key);
};
</script>

<template>
  <div
    class="flex gap-2 justify-evenly p-1 bg-[#1f272b] border border-gray-50/15 rounded-full max-w-full"
  >
    <button
      v-for="{ key, label } in props.options"
      tabindex="-1"
      :key="key"
      @click="handleSelected(key)"
      :class="[
        'px-4 grow py-2 rounded-full cursor-pointer text-white lg:!w-auto font-semibold',
        selected === key && (props.selectedOptionClass ?? '  bg-neutral-200 !text-black'),
      ]"
      :style="`width: ${100 / props.options.length}%`"
    >
      {{ label.toUpperCase() }}
    </button>
  </div>
</template>
