import type { inferRouterOutputs } from "@trpc/server";
import type { AppRouter } from "svc-auction";

type RouterOutput = inferRouterOutputs<AppRouter>;

export type BidsList = RouterOutput["auctions"]["orders"]["list"];
export type SKillSalesList = RouterOutput["sales"]["skills"]["list"];

export enum PaymentMethod {
  CRYPTO = "crypto",
  CREDIT_CARD = "creditCard",
}
