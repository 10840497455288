<script setup lang="ts">
import { computed, withDefaults } from "vue";
import { Heart } from "lucide-vue-next";

const props = withDefaults(
  defineProps<{
    name?: string;
    fill?: string;
    stroke?: string;
    defaultStroke?: string;
    isSelected?: boolean;
    fallbackFunc?: () => void;
    size?: "sm" | "md" | "lg";
  }>(),
  {
    isSelected: false,
    fill: "none",
    stroke: "#FFFFFF",
    defaultStroke: "#FFFFFF",
  }
);

const handleClick = () => {
  if (props.fallbackFunc) {
    props.fallbackFunc();
  }
};

const cssSize = computed(() => {
  if (props.size === "sm") return "h-4 w-4";
  if (props.size === "md") return "h-6 w-6";
  return "h-4 w-4 md:h-8 md:w-8";
});

const computedFill = computed(() => {
  if (props.isSelected) return props.fill;
  return undefined;
});

const computedStroke = computed(() => {
  if (props.isSelected) {
    return props.stroke;
  }

  return props.defaultStroke;
});
</script>

<template>
  <div
    @click="handleClick"
    class="lg:flex items-center gap-1.5 rounded-lg px-2 py-2 cursor-pointer"
  >
    <Heart :class="cssSize" :fill="computedFill" :color="computedStroke" class="mb-[1px]" />

    <span v-if="name" class="select-none text-sm truncate whitespace-nowrap">
      {{ name }}
    </span>
  </div>
</template>
