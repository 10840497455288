<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import useDynamicSort from "~/composables/useDynamicSort";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { useStoreApp } from "~/stores/storeApp";
import {
  CurrentlyAvailableCardAuction,
  useCardDetails,
} from "~/components/card-details/use-card-details";
import DetailsSection from "~/components/card-details/DetailsSection.vue";
import AvailableCardsTable from "~/components/card-details/AvailableCards/AvailableCardsTable.vue";
import AvailableCardCard from "~/components/card-details/AvailableCards/AvailableCardCard.vue";
import { MarketType } from "fungi-types";
const { t } = useI18n();

const cardDetails = useCardDetails();
const { cardSales, selectedSkill, loadingSales } = storeToRefs(cardDetails);

const availableCards = useDynamicSort(cardSales);

function openBidModalForToken(token: CurrentlyAvailableCardAuction) {
  useStoreMarketplace().selectedItem = token;

  if (token.marketType === MarketType.PRIMARY) {
    useStoreApp().setBidModalState(true);
  } else {
    useStoreApp().showBuyModal = true;
  }
}
</script>

<template>
  <DetailsSection class="relative" :name="t('cardDetails.availableCards.title')">
    <div class="relative flex w-full flex-col">
      <Loader v-if="loadingSales" class="bg-black/90 overflow-auto rounded-md" />
      <AvailableCardsTable @bid-or-buy="openBidModalForToken" class="hidden lg:table" />
      <ol class="flex lg:hidden flex-col items-stretch gap-4">
        <li v-for="card in availableCards.sorted.value" :key="card.cuid">
          <AvailableCardCard
            :card="card"
            :skill="selectedSkill!"
            @bid-or-buy="openBidModalForToken"
          />
        </li>
        <li v-if="availableCards.sorted.value.length === 0">
          <div
            class="bg-black bg-opacity-25 border border-grey-30 border-opacity-20 rounded-lg p-4 flex flex-col items-stretch gap-4"
          >
            {{ t("cardDetails.availableCards.table.noCards") }}
          </div>
        </li>
      </ol>
    </div>
  </DetailsSection>
</template>
