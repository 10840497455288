<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useStoreUserV2 } from "~/stores/storeUserV2";

const { t } = useI18n();

const userStore = useStoreUserV2();
</script>

<template>
  <div class="grid grid-cols-1 grid-rows-1 gap-2" ref="overlayParentRef">
    <div
      class="lg:col-span-3 px-5 py-3 flex flex-row items-center justify-between gap-2 lg:gap-1 bg-slate-900 rounded-lg"
    >
      <span class="text-xs text-slate-400">
        {{ t("drawers.profile.defaultView.token.balance", "Balance") }}
      </span>
      <div class="flex flex-row items-center gap-1">
        <span class="font-semibold text-base text-white"> {{ userStore.tokenBalance }}&nbsp; </span>
        <icon-fg-fungiball-token class="w-5" />
      </div>
    </div>
  </div>
</template>
