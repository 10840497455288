export const storeUtmData = (): void => {
  const params = new URLSearchParams(window.location.search);
  const utmKeys = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "utm_id"];

  const utmData: Record<string, string> = {};
  let hasUtm = false;

  utmKeys.forEach((key) => {
    const value = params.get(key);
    if (value) {
      utmData[key] = value;
      hasUtm = true;
    }
  });

  if (hasUtm) {
    localStorage.setItem("utm-data", JSON.stringify(utmData));
  }
};
