<script setup lang="ts">
import { ref, watch } from "vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { Sheet } from "~/shadcn/components/ui/sheet";
import { Drawer } from "~/shadcn/components/ui/drawer";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import ProfileDrawerDefaultView from "~/components/profile/views/ProfileDrawerDefaultView.vue";

const userStore = useStoreUserV2();

const breakpoints = useBreakpoints(breakpointsTailwind);

const breakpointIsAboveLg = breakpoints.greaterOrEqual("lg");

const open = ref(userStore.profileDrawerPage !== "");

watch(userStore, (newStore) => {
  open.value = newStore.profileDrawerPage !== "";
});

watch(open, (newOpenState) => {
  if (!newOpenState) userStore.closeProfileDrawer();
});
</script>

<template>
  <component :is="breakpointIsAboveLg ? Sheet : Drawer" v-model:open="open">
    <ProfileDrawerDefaultView v-if="userStore.profileDrawerPage === 'default'" />
    <ProfileDrawerSettingsView v-else-if="userStore.profileDrawerPage === 'settings'" />
  </component>
</template>
