<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useFloating, offset, shift, flip, autoUpdate } from "@floating-ui/vue";
import { ChevronDown } from "lucide-vue-next";

// Utils
import { useI18n } from "vue-i18n";
import { BigNumber } from "ethers";
import { roundToDecimals } from "~/utils/math";
import useConvert from "~/composables/useConvert";
import { formatDate } from "~/utils/dates";

// Api
import { auctionApi } from "~/api/auction";

// Stores
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import usePOLPrice from "~/stores/POLprice";

// Payments
import Currency from "~/common/enums/Currency";

// Types
import { MarketType } from "fungi-types";
import { BidsList, PaymentMethod } from "~/types/api-auction";

const props = withDefaults(
  defineProps<{
    marketType: MarketType;
    count: number;
    currencySymbol: string;
    paymentMethod: PaymentMethod;
    currency: Currency;
  }>(),
  {
    count: 0,
  }
);

const { t } = useI18n();
const storeMarketplace = useStoreMarketplace();

const { weiToMatic } = useConvert;
const polPrice = usePOLPrice();

//// MODAL BID NEW

const handleResize = () => {
  if (isHovered.value) {
    update();
  }
};

onMounted(() => {
  // Add resize event listener
  window.addEventListener("resize", handleResize);

  // Alternatively, use autoUpdate for automatic positioning
  // This handles resize, scroll, and other layout changes
  const cleanup = autoUpdate(triggerList.value, dropdown.value, update, {
    animationFrame: true, // Use requestAnimationFrame for smoother updates
  });

  // Store cleanup function to call on unmount
  onBeforeUnmount(cleanup);
});

// Or if not using autoUpdate, clean up manual resize listener
onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

const lastCount = ref(0);
const bids = ref<BidsList>([]);
const loadingBids = ref(false);

const fetchBids = async () => {
  if (storeMarketplace.selectedItem.countOrders === lastCount.value) return;
  lastCount.value = storeMarketplace.selectedItem.countOrders;
  loadingBids.value = true;
  const list = await auctionApi.auctions.orders.list.query({
    auctionId: storeMarketplace.selectedItem.cuid,
    count: 20,
  });
  bids.value = list;
  loadingBids.value = false;
};

const bidsList = computed(() => {
  if (loadingBids.value) return [];
  return bids.value.map((bid) => {
    let amount: BigNumber | number = BigNumber.from(bid.amount);
    if (props.paymentMethod === PaymentMethod.CREDIT_CARD) {
      amount = roundToDecimals(
        polPrice.weiToCurrency(BigNumber.from(bid.amount), props.currency),
        2
      );
    } else {
      amount = roundToDecimals(weiToMatic(amount || 0), 2);
    }
    return {
      ...bid,
      amount,
      date: formatDate(bid.date, "dd MMM h:m:s"),
    };
  });
});

// Reference to the trigger element (what gets hovered)
const triggerList = ref(null);
// Reference to the tooltip/content that appears on hover
const dropdown = ref(null);
// Track hover state
const isHovered = ref(false);
// Track if the user is on mobile
const isMobile = ref(false);

const checkMobile = () => {
  isMobile.value = window.innerWidth < 768;
};

const { floatingStyles, update } = useFloating(triggerList, dropdown, {
  placement: "bottom-start",
  middleware: [offset(0), shift(), flip()],
});

// Event handlers for mouse interactions
const handleMouseEnter = () => {
  fetchBids();
  checkMobile();
  isHovered.value = true;
};

const handleMouseLeave = () => {
  isHovered.value = false;
};

onMounted(() => fetchBids());
</script>

<template>
  <div
    @mouseover="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    class="w-full md:w-fit justify-between rounded-full border-neutral-900 border px-3 p-1 text-xs flex flex-row items-center gap-2 pr-3 cursor-pointer"
    :class="[isHovered && ' bg-neutral-900']"
    ref="triggerList"
  >
    <div class="text-nowrap whitespace-nowrap flex gap-2">
      <div>
        {{ props.count }}
        {{
          t("_shared.auction", {
            count: storeMarketplace.selectedItem.countOrders,
          }).toLowerCase()
        }}
      </div>
    </div>
    <ChevronDown class="h-4 w-4" />
    <div
      ref="dropdown"
      :style="[floatingStyles, { visibility: isHovered ? 'visible' : 'hidden' }]"
      class="bg-neutral-200 rounded-md z-50 flex flex-col gap-1 text-neutral-900 border border-white/10 max-h-72"
      :class="[isMobile && '!fixed !top-auto !translate-x-0 max-h-60 !bottom-0 !left-0 !right-0']"
    >
      <div class="flex flex-col divide-y divide-neutral-300 h-full overflow-auto">
        <div
          v-for="bid in bidsList"
          :key="bid.orderId"
          class="flex min-w-64 text-xs justify-between items-center p-3"
        >
          <div>
            <div class="text-black">{{ bid.bidder }}</div>
            <div class="text-neutral-500">{{ bid.date }}</div>
          </div>
          <div class="text-sm font-medium flex flex-row whitespace-nowrap items-center gap-1">
            {{ bid.amount }}
            <template v-if="paymentMethod === PaymentMethod.CRYPTO">
              <icon-fgc-w-matic class="w-3 h-3 mb-[1px]" />
            </template>
            <template v-else> {{ currencySymbol }} </template>
          </div>
        </div>
      </div>
      <div
        @click="handleMouseLeave"
        class="text-center p-3 border-t font-semibold border-neutral-400 active:bg-neutral-300 cursor-pointer"
        :class="[!isMobile && 'hidden']"
      >
        {{ t("_shared.close") }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
