<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { formatDistanceDateToNow } from "~/utils/dates";
import { useCardDetails } from "~/components/card-details/use-card-details";
import useDynamicSort from "~/composables/useDynamicSort";
import Loader from "~/components/dashboard/Loader.vue";

const { t } = useI18n();

const cardDetails = useCardDetails();
const { ownersHistory: ownersHistoryRef, loadingOwnersHistory } = storeToRefs(cardDetails);

const ownersHistory = useDynamicSort(ownersHistoryRef);
</script>

<template>
  <div class="flex gap-2 flex-col relative">
    <Loader class="bg-black/90" v-if="loadingOwnersHistory" />
    <div
      class="bg-black rounded-md p-4 flex w-full text-sm gap-2 items-center justify-between border border-grey-30 border-opacity-20"
      v-if="cardDetails.ownersHistory.length === 0"
    >
      {{ t("cardDetails.cardPreviousOwners.noPreviousOwners", "No previous owners") }}
    </div>
    <div class="flex flex-col" v-for="owner in cardDetails.ownersHistory" :key="owner.transaction">
      <div
        class="bg-black rounded-md p-4 flex w-full text-sm gap-2 items-center justify-between border border-grey-30 border-opacity-20"
      >
        <div class="w-full">
          <div class="font-light">
            {{
              owner.price
                ? t("cardDetails.cardPreviousOwners.boughtBy")
                : t("cardDetails.cardPreviousOwners.won")
            }}
            <span
              class="underline-offset-4 underline decoration-dotted text-neutral-200 font-semibold"
              >{{ owner.owner }}</span
            >
          </div>
          <div class="text-xs text-neutral-400 font-light">
            {{ formatDistanceDateToNow(owner.acquiredAt) }}
          </div>
        </div>
        <div class="text-lg text-nowrap font-semibold flex-nowrap flex gap-2">
          <template v-if="owner.price"
            >{{ owner.price.toFixed(2) }} <IconFgcWMatic class="w-4"
          /></template>
        </div>
      </div>
    </div>
  </div>
</template>
