<script setup lang="ts">
import { Skill } from "fungi-types";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  activeSkill: Skill;
  skillScores: Record<Skill, number>;
}>();

const emits = defineEmits<{
  (event: "change", skill: Skill): void;
}>();

const { t } = useI18n();

const skills = [Skill.POWER, Skill.SERVICE, Skill.RETURN, Skill.MENTAL];

function changeSkill(newSkill: Skill) {
  if (newSkill === props.activeSkill) return;

  emits("change", newSkill);
}
</script>

<template>
  <form @submit.prevent class="flex flex-row items-stretch text-sm">
    <button
      v-for="skill in skills"
      :key="skill"
      @click.stop.prevent="changeSkill(skill)"
      class="relative group grow flex flex-row items-center justify-center gap-2 p-3 font-semibold border-b border-slate-700 transition-all duration-100 rounded-t-md"
    >
      <span class="uppercase hidden lg:block">{{ t(`skills.${skill}`) }}</span>
      <span
        class="align-middle px-2 rounded-md font-semibold text-neutral-950"
        :class="`bg-skill-${skill}`"
      >
        {{ props.skillScores[skill] ? props.skillScores[skill] : 0 }}
      </span>

      <span class="lg:hidden">
        <SkillIcon :skill="skill" />
      </span>

      <div
        class="h-0 w-full absolute bottom-0 opacity-0 group-hover:opacity-100 transition-all duration-300 group-hover:h-0.5"
        :class="{
          'bg-skill-mental': skill === Skill.MENTAL,
          'bg-skill-service': skill === Skill.SERVICE,
          'bg-skill-power': skill === Skill.POWER,
          'bg-skill-return': skill === Skill.RETURN,
          'opacity-100 h-0.5': props.activeSkill === skill,
        }"
      ></div>
    </button>
  </form>
</template>
