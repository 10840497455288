import { defineStore } from "pinia";
import { MarketplaceApi } from "~/api/marketplace";
import { IAuctionItem, IMarketplaceFilter, IMetaData } from "~/common/interfaces/IMarketplace";
import { BigNumber, Contract } from "ethers";
import { secondaryExecutorConfig } from "~/generated";
import { useStoreContract } from "~/stores/storeContract";
import { isProd } from "~/application/config";
import useConvert from "~/composables/useConvert";
import i18n from "~/modules/i18n";
import { useStoreUserV2 } from "./storeUserV2";
import { useStoreApp, NotifType } from "./storeApp";
import { AuctionUpdate, MarketType } from "fungi-types";
import { useTracking } from "~/composables/useGtm";

const { weiToMatic, maticToWei } = useConvert;

export const useSecondaryMarketplace = defineStore("secondaryMarketplace", {
  state: () => ({
    api: new MarketplaceApi(MarketType.SECONDARY),
    auctions: [] as Array<IAuctionItem>,
    stickyAuctions: [] as Array<IAuctionItem>,
    privateAuctions: [] as Array<IAuctionItem>,
    meta: {} as IMetaData,
    filters: {},
    loading: false,
  }),

  getters: {
    activeFilters(state) {
      return Object.values(state.filters).reduce((count: number, value) => {
        if ((typeof value === "string" || Array.isArray(value)) && value.length > 0) {
          return count + 1;
        }
        return count;
      }, 0);
    },
  },

  actions: {
    async getCards() {
      this.loading = true;
      const response = await this.api.getCards(this.filters);
      [this.auctions, this.meta] = [response.data, response.meta];
      this.loading = false;
    },

    async updateFilters(filters: Partial<IMarketplaceFilter>) {
      this.filters = { ...filters };
      if (filters.price?.[0] && filters.price?.[1]) {
        filters.price[0] = maticToWei(filters.price[0] as string).toString();
        filters.price[1] = maticToWei(filters.price[1] as string).toString();
      }
      await this.getCards();
    },

    async nextPage() {
      this.loading = true;
      if (this.meta.next_page_url) {
        const response = await this.api.getCards(this.filters, {
          page: this.meta.current_page + 1,
        });
        this.auctions.push(...response.data);
        this.meta = response.meta;
      }
      this.loading = false;
    },

    async resetFilters() {
      this.filters = {} as IMarketplaceFilter;
      await this.getCards();
    },

    updateAuction(data: AuctionUpdate) {
      this.$patch((state) => {
        const auctionIdx = state.auctions.findIndex(
          (auction) => auction.cuid === data.auction.cuid
        );

        if (auctionIdx === -1) return;

        state.auctions[auctionIdx].status = data.auction.status as any;
      });
    },

    removeCard(cuid: string) {
      this.$patch((state) => {
        const index = state.auctions.findIndex((auction) => auction.cuid === cuid);
        if (index !== -1) {
          state.auctions.splice(index, 1);
        }
      });
    },

    async buyItem(item: IAuctionItem) {
      const storeUser = useStoreUserV2();
      const storeApp = useStoreApp();

      if (storeUser.isWalletConnected) {
        const storeContract = useStoreContract();
        const { t } = i18n.global;
        const itemPrice = {
          wei: BigNumber.from(item.startedAmount),
          matic: weiToMatic(BigNumber.from(item.startedAmount)),
        };

        if (itemPrice.matic > storeUser.availableWMaticBalance) {
          throw new Error(t("_payment.insufficient-wpol"));
        }

        const contract = new Contract(
          isProd
            ? "0xD8Ed67279345Da8B9A55be1fF464B309bBc3B6b0"
            : secondaryExecutorConfig.address["80001"],
          secondaryExecutorConfig.abi,
          storeUser.wallet.signer
        );

        const allowance = await storeContract.getAllowedWmtc(
          storeUser.walletAddress,
          contract.address
        );

        if (allowance <= itemPrice.matic) {
          await storeContract.allowWmtc(100000, contract.address);
        }

        const buyParams = await this.api.buy(item.cuid);
        const gasAmount = await contract.estimateGas.buy({
          signature: buyParams.signature,
          parameters: buyParams.parameters,
        });
        const gasPrice = await storeUser.getGasPrice();
        const gasFee = gasPrice.mul(gasAmount).mul(200).div(100);

        if (gasFee.gt(storeUser.weiMaticBalance)) {
          storeApp.displayNotification(
            NotifType.BUY_FAILED,
            item,
            t("pages.marketplace.trade.errors.insufficient-wmatic-balance")
          );
          return;
        }

        const gtm = useTracking();
        const tx = await contract.buy({
          signature: buyParams.signature,
          parameters: buyParams.parameters,
        });
        await tx.wait();
        gtm.trackSuccessfulBuy({
          market: MarketType.SECONDARY,
          username: storeUser.username,
          user_id: storeUser.cuid,
          value: itemPrice.wei,
          currency: "POL",
          token_id: item.card?.tokenId,
          auction_id: item.cuid,
          nft_type: item.type,
          payment_method: "web3",
        });
        await storeUser.getWMaticBalance();

        storeApp.displayNotification(NotifType.BUY_SUCCESS, item);
      }
    },
  },
});
