import { makeApi } from "~/api/index";

const baseUrl = import.meta.env.VITE_API_URL;
const api = makeApi(`${baseUrl}/onboarding`);

export const onboardingApi = {
  async hideOnboarding() {
    await api.put("/", {
      showOnboarding: false,
    });
  },
};
