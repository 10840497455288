import { defineStore } from "pinia";
import { MarketplaceApi } from "~/api/marketplace";
import { IAuctionItem, IMarketplaceFilter, IMetaData } from "~/common/interfaces/IMarketplace";

import useConvert from "~/composables/useConvert";
import { Scarcity, AuctionUpdate, MarketType } from "fungi-types";
import { BigNumber } from "ethers";

const { maticToWei } = useConvert;

export const usePrimaryMarketplace = defineStore("primaryMarketplace", {
  state: () => ({
    api: new MarketplaceApi(MarketType.PRIMARY),
    auctions: [] as Array<IAuctionItem>,
    stickyAuctions: [] as Array<IAuctionItem>,
    meta: {} as IMetaData,
    filters: {} as Partial<IMarketplaceFilter>,
    loading: false,
  }),

  getters: {
    activeFilters(state) {
      return Object.values(state.filters).reduce((count: number, value) => {
        if ((typeof value === "string" || Array.isArray(value)) && value.length > 0) {
          return count + 1;
        }
        return count;
      }, 0);
    },
  },

  actions: {
    filterStickyAuctions(auctions: Array<IAuctionItem>) {
      return auctions.filter(
        (auction) => this.stickyAuctions.findIndex((sticky) => sticky.cuid === auction.cuid) === -1
      );
    },
    async getCards() {
      try {
        this.loading = true;
        if (this.activeFilters === 0) await this.getStickyCards();
        else this.stickyAuctions = [];
        const response = await this.api.getCards(this.filters);
        const [auctions, meta] = [response.data, response.meta];
        this.auctions = this.filterStickyAuctions(auctions);

        this.meta = meta;
        this.loading = false;
      } catch (e) {
        console.log("e", e);
      }
    },

    async getStickyCards() {
      try {
        const response = await this.api.getCards({
          player_name: "medvedev",
          scarcity: [Scarcity.GOLD, Scarcity.SILVER],
        });
        const [auctions, meta] = [response.data, response.meta];
        this.stickyAuctions = auctions;
        return { auctions, meta };
      } catch (e) {
        console.log("e", e);
      }
    },

    async updateFilters(filters: Partial<IMarketplaceFilter>) {
      this.filters = { ...filters };
      if (filters.price?.[0] || filters.price?.[1]) {
        filters.price[0] = maticToWei(filters.price[0] as string).toString();
        filters.price[1] = maticToWei(filters.price[1] as string).toString();
      }
      await this.getCards();
    },

    async nextPage() {
      this.loading = true;
      if (this.meta.next_page_url) {
        const response = await this.api.getCards(this.filters, {
          page: this.meta.current_page + 1,
        });
        const filterAuctions = this.filterStickyAuctions(response.data);
        this.auctions.push(...filterAuctions);
        this.meta = response.meta;
      }
      this.loading = false;
    },

    async resetFilters() {
      this.filters = {} as IMarketplaceFilter;
      await this.getCards();
    },

    async updateAuction(
      data: AuctionUpdate,
      auctionsSrc?: Array<IAuctionItem>
    ): Promise<{
      prevBidder: { username: string | undefined; cuid: string | undefined } | null;
      item: IAuctionItem;
      index: number;
    } | null> {
      const auctions = auctionsSrc ?? this.auctions;

      // Find the auction to update
      const index = auctions.findIndex((auction) => auction.cuid === data.auction.cuid);
      if (index === -1) return null;
      const auction = auctions[index];

      // Validate that new bid is after and has a higher amount than the last known bid
      const newBidAmount = BigNumber.from(data.auction.lastBidAmount);
      const lastBidAmount = BigNumber.from(auction.lastBidAmount ?? "0");
      const newBidAt = new Date(data.auction.lastBidAt);
      const lastBidAt = new Date(auction.lastBidAt ?? "");
      const isNewBidBeforeLastKnownBid = newBidAt.getTime() < lastBidAt.getTime();
      const isNewBidLesserThanLastKnownBid = newBidAmount.lt(lastBidAmount);
      if (isNewBidBeforeLastKnownBid || isNewBidLesserThanLastKnownBid) return null;

      // Get the last known bidder info before overwriting the auction's data
      const prevBidder = {
        username: auction.lastBidder?.username,
        cuid: auction.lastBidder?.cuid,
      };

      auction.lastBidder = {
        username: data.bidder.username,
        cuid: data.bidder.cuid,
      };
      auction.lastBidAt = data.auction.lastBidAt;
      auction.endedAt = data.auction.endedAt;
      auction.lastBidAmount = data.auction.lastBidAmount.toString();
      auction.countOrders = data.auction.countOrders;
      auction.status = data.auction.status;

      auctions[index] = auction;

      return { prevBidder, item: auctions[index], index };
    },
  },
});
