<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { roundToDecimals } from "~/utils/math";
import { useStoreApp } from "~/stores/storeApp";

const { t } = useI18n();

const storeApp = useStoreApp();
const userStore = useStoreUserV2();

const overlayParentRef = ref<HTMLElement>();

const showDepositModal = () => {
  userStore.closeProfileDrawer();
  storeApp.showDepositModal = true;
};
const showSwapModal = () => {
  userStore.closeProfileDrawer();
  storeApp.showSwapModal = true;
};
const showTransferModal = () => {
  userStore.closeProfileDrawer();
  storeApp.showTransferModal = true;
};
</script>

<template>
  <div class="flex flex-col gap-2">
    <div
      class="grid grid-cols-1 grid-rows-4 lg:grid-cols-3 lg:grid-rows-2 gap-2"
      ref="overlayParentRef"
    >
      <div
        class="lg:col-span-3 px-5 py-3 flex flex-row items-center justify-between gap-2 lg:gap-1 bg-slate-900 rounded-lg"
      >
        <span class="text-xs text-slate-400">
          {{ t("drawers.profile.defaultView.wallet.balance", "Balance") }} POL
        </span>
        <span class="font-semibold text-base text-white flex flex-row gap-2 items-center">
          {{ roundToDecimals(userStore.currentMaticBalance, 2) }}
          <img src="/images/pol.svg" class="w-5 mb-0.5" alt="POL" />
        </span>
      </div>
      <div
        class="lg:col-span-3 px-5 py-3 flex flex-row items-center justify-between gap-2 lg:gap-1 bg-slate-900 rounded-lg"
      >
        <span class="text-xs text-slate-400">
          {{ t("drawers.profile.defaultView.wallet.balance", "Balance") }} WPOL
        </span>
        <span class="font-semibold text-base text-white flex flex-row gap-2 items-center">
          {{ roundToDecimals(userStore.availableWMaticBalance, 2) }}
          <img src="/images/wpol.svg" class="w-5 mb-0.5" alt="WPOL" />
        </span>
      </div>

      <div
        class="row-span-2 lg:row-span-1 lg:col-span-2 grid grid-rows-2 lg:grid-rows-1 grid-cols-1 lg:grid-cols-2 gap-2"
      >
        <button
          class="rounded-lg bg-slate-900 hover:bg-slate-800 py-3 px-4 flex flex-col items-center justify-center lg:justify-around gap-2 lg:gap-1"
          @click="showDepositModal"
          tabindex="-1"
        >
          <img src="/images/deposit-pol.svg" alt="Deposit POL" />
          <span class="text-xs text-slate-400">
            {{ t("drawers.profile.defaultView.wallet.deposit", "Deposit") }}
          </span>
        </button>

        <button
          class="rounded-lg bg-slate-900 hover:bg-slate-800 py-3 px-4 flex flex-col items-center justify-center lg:justify-around gap-2 lg:gap-1"
          @click="showSwapModal"
          tabindex="-1"
        >
          <img src="/images/convert-pol-to-wpol.svg" alt="Convert POL to WPOL" />
          <span class="text-xs text-slate-400">
            {{ t("drawers.profile.defaultView.wallet.convert", "Convert") }}
          </span>
        </button>
      </div>

      <button
        class="rounded-lg bg-slate-900 hover:bg-slate-800 py-3 px-4 flex flex-col items-center justify-center lg:justify-around gap-2 lg:gap-1"
        @click="showTransferModal"
        tabindex="-1"
      >
        <img src="/images/withdraw-wpol.svg" alt="Withdraw WPOL" />
        <span class="text-xs text-slate-400">
          {{ t("drawers.profile.defaultView.wallet.withdraw", "Withdraw") }}
        </span>
      </button>
    </div>
    <div>
      <ProfileDrawerCopyWalletAddressButton />
    </div>
  </div>
</template>
