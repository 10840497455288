<script setup lang="ts">
import { useStoreUserV2 } from "~/stores/storeUserV2";
import Currency from "~/common/enums/Currency";
import { getCurrencySymbol } from "~/utils/currency";
import { useI18n } from "vue-i18n";
import { useId } from "vue";

const { t } = useI18n();
const id = useId();

const userStore = useStoreUserV2();

const availableCurrencies = [
  { key: Currency.USD, label: getCurrencySymbol(Currency.USD) },
  { key: Currency.WMATIC, label: getCurrencySymbol(Currency.WMATIC) },
  { key: Currency.EUR, label: getCurrencySymbol(Currency.EUR) },
];

const handleUpdatePreferredCurrency = (currency: string) => {
  userStore.updatePreferredCurrency(currency as Currency);
};
</script>

<template>
  <fieldset class="flex flex-col gap-1 items-stretch max-w-full overflow-hidden">
    <label :for="id" class="text-xs text-grey-40">
      {{ t("drawers.profile.settingsView.preferences.preferredCurrency", "Preferred currency") }}
    </label>
    <PillOptions
      :id="id"
      v-model:selected="userStore.preferredCurrency"
      @selected="handleUpdatePreferredCurrency"
      class="bg-slate-950 !p-0"
      :options="availableCurrencies"
      selected-option-class="bg-primary-800 border !border-primary-60 !w-auto"
    />
    <p class="text-xs text-neutral-500">
      {{
        t(
          "drawers.profile.settingsView.preferences.currencyForCardsPrices",
          "Currency used for cards prices"
        )
      }}
    </p>
  </fieldset>
</template>
