<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import Modal from "@/components/modals/ModalContainer.vue";
import { useNotificationCenter } from "~/stores/storeNotificationCenter";
import { Bell } from "lucide-vue-next";

const { t } = useI18n();

const text = computed(() => ({
  title: t("modals.notificationPermission.title"),
  description: t("modals.notificationPermission.description"),
  accept: t("modals.notificationPermission.accept"),
  deny: t("modals.notificationPermission.deny"),
  messageWithDenied: t("modals.notificationPermission.messageWithDenied"),
}));

const notificationCenter = useNotificationCenter();
const showModal = ref(false);
const browserPermission = ref(Notification.permission);
const showBlockedMessage = ref(false);
const showButton = ref(false);

onMounted(() => {
  // Show the modal after 6 seconds
  setTimeout(() => {
    handleNotificationPermission();
  }, 6000);
});

const handleNotificationPermission = () => {
  const storedPermission = localStorage.getItem("notification-permission");
  browserPermission.value = Notification.permission;

  if (!("Notification" in window)) {
    // Case 1: Web push notifications not supported by browser
    showModal.value = false;
    showBlockedMessage.value = false;
    showButton.value = false;
  } else if (
    !storedPermission ||
    (storedPermission === "granted" && browserPermission.value === "default")
  ) {
    // Case 2: No permission in storage
    showModal.value = true;
    showBlockedMessage.value = false;
    showButton.value = true;
  } else if (storedPermission === "granted" && browserPermission.value === "denied") {
    // Case 3: Permission granted in storage but denied in browser
    showModal.value = true;
    showBlockedMessage.value = true;
    showButton.value = false;
  }
};

const requestPermission = async () => {
  let permission = browserPermission.value;

  if (permission === "denied") {
    showBlockedMessage.value = true;
    browserPermission.value = permission;
    showButton.value = false;
    return;
  }

  if (permission === "default" || permission === "granted") {
    permission = await notificationCenter.requestPushNotificationsPermission();
    browserPermission.value = permission;
    if (permission === "denied") {
      showModal.value = true;
      showBlockedMessage.value = true;
      showButton.value = false;
    } else {
      localStorage.setItem("notification-permission", permission);
      showModal.value = false;
    }
  }
};

const denyPermission = () => {
  localStorage.setItem("notification-permission", "denied");
  showModal.value = false;
};
</script>

<template>
  <Modal v-if="showModal" :show="showModal" :showCloseButton="true" @onClose="showModal = false">
    <div
      class="text-white max-w-full md:max-w-md lg:max-w-lg w-full p-4 md:p-6 rounded-2xl text-center"
    >
      <h2 class="text-lg md:text-xl font-bold flex items-center justify-center gap-2">
        {{ text.title }} <Bell class="w-5 h-5 md:w-6 md:h-6 text-[#D7FF26]" />
      </h2>
      <p class="text-gray-400 mt-2 text-sm md:text-base">
        {{ text.description }}
      </p>
      <div class="flex justify-center mt-4 md:mt-6">
        <Bell class="w-12 h-12 md:w-16 md:h-16 text-white" />
      </div>

      <!-- Displayed when nofitication is blocked in browsers -->
      <div
        v-if="showBlockedMessage"
        class="p-4 mt-4 text-sm text-yellow-800 rounded-lg bg-yellow-50"
        role="alert"
      >
        {{ text.messageWithDenied }}
      </div>

      <Button
        v-if="showButton"
        @click="requestPermission"
        type="primary-blue"
        class="w-full mt-2 md:mt-6"
      >
        {{ text.accept }}
      </Button>
      <button
        v-if="showButton"
        @click="denyPermission"
        class="mt-3 text-gray-400 underline text-xs md:text-sm"
      >
        {{ text.deny }}
      </button>
    </div>
  </Modal>
</template>
