import { ref } from "vue";
import { userApi } from "~/api/user";
import type { UserEquipment } from "~/common/interfaces/IUser";
import i18n from "~/modules/i18n";
import { showNotification, ToastType } from "~/shadcn/components/ui/sonner/custom-toast";

const selectedEquipment = ref<UserEquipment | null>(null);
const nextLevel = ref<number | null>(null);

export const useUpgradeEquipment = () => {
  const setNextLevel = (equipment: UserEquipment) => {
    nextLevel.value = equipment.level + 1;
  };

  const setSelectedEquipment = (equipment: UserEquipment | null) => {
    selectedEquipment.value = equipment;
  };

  const clearSelectedEquipment = () => {
    selectedEquipment.value = null;
  };

  const upgradeEquipment = async (identifier: string) => {
    try {
      const equipment = await userApi.upgradeEquipment(identifier, selectedEquipment.value?.id!);

      if (!equipment) {
        return null;
      }

      selectedEquipment.value = {
        ...selectedEquipment.value,
        title: selectedEquipment.value!.title!,
        brand: selectedEquipment.value!.brand!,
        edition: selectedEquipment.value!.edition!,
        image: equipment.image!,
        model_url: selectedEquipment.value!.model_url!,
        owner: selectedEquipment.value!.owner!,
        bonus: equipment.bonus!,
        level: equipment.level!,
        card_type: selectedEquipment.value!.card_type!,
        id: selectedEquipment.value!.id!,
      };
      return equipment;
    } catch (e) {
      showNotification({
        type: ToastType.ERROR,
        message: i18n.global.t("modals.upgrade-equipment.error"),
      });
      return null;
    }
  };

  const unlockEquipment = async (identifier: string) => {
    try {
      const equipment = await userApi.unlockEquipment(identifier, selectedEquipment.value?.id!);
      if (!equipment) {
        return null;
      }
      return equipment;
    } catch (error) {
      showNotification({
        type: ToastType.ERROR,
        message: i18n.global.t("modals.equipmentUnlock.error"),
      });
      return null;
    }
  };

  return {
    nextLevel,
    selectedEquipment,
    setSelectedEquipment,
    clearSelectedEquipment,
    upgradeEquipment,
    setNextLevel,
    unlockEquipment,
  };
};
