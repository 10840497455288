<script setup lang="ts">
import { RouterView } from "vue-router";
import PanelController from "@/components/panel/PanelController.vue";
import { NotifType, useStoreApp } from "~/stores/storeApp";
import { useHead } from "@vueuse/head";
import GlobalModals from "~/components/GlobalModals.vue";
import { usePrimaryMarketplace } from "~/stores/storePrimaryMarketplace";
import { onMounted, onUnmounted } from "vue";
import { Toaster } from "~/shadcn/components/ui/sonner";
import { storeUtmData } from "./utils/utmTracker";

const storeApp = useStoreApp();
useHead({
  title: "Fungiball",
  meta: [
    { name: "description", content: "Fungiball Tennis Fantasy Game" },
    {
      name: "theme-color",
      content: "#000000",
    },
  ],
  link: [
    {
      rel: "icon",
      type: "image/svg+xml",
      href: "/favicon.svg",
    },
  ],
});

//keyboard handler
function keyHandler(e: KeyboardEvent) {
  if (e.type !== "keydown") return;

  if (e.key === "Escape") {
    storeApp.showBidModal = false;
  }
  if (e.altKey && e.key === "ArrowUp") {
    storeApp.debug = !storeApp.debug;
  }
  if (e.altKey && ["w", "∑"].includes(e.key)) {
    useStoreApp().displayNotification(NotifType.WIN, usePrimaryMarketplace().auctions[0]);
  }
  if (e.altKey && ["o", "ø"].includes(e.key)) {
    useStoreApp().displayNotification(NotifType.OUTBID, usePrimaryMarketplace().auctions[0]);
  }
  if (e.altKey && ["v", "√"].includes(e.key)) {
    useStoreApp().displayNotification(NotifType.OVERBID, usePrimaryMarketplace().auctions[0]);
  }
  if (e.altKey && ["t", "†"].includes(e.key)) {
    useStoreApp().displayNotification(NotifType.TOPBID, usePrimaryMarketplace().auctions[0]);
  }
}

onMounted(async () => {
  window.addEventListener("keydown", keyHandler);
  storeUtmData();
});

onUnmounted(() => {
  window.removeEventListener("keydown", keyHandler);
});
</script>

<template>
  <div class="flex flex-col h-full min-h-screen overflow-hidden" @keyup="keyHandler">
    <Transition name="fade" mode="out-in">
      <div
        v-if="!storeApp.initialized"
        class="fixed inset-0 flex items-center justify-center bg-black z-50"
      >
        <img src="/favicon.svg" alt="step image" class="animate-pulse w-20 md:w-40" />
      </div>
    </Transition>

    <router-view />
    <PanelController />
    <GlobalModals />
    <Toaster />
    <ModalNotificationPermission />
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity ease-linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
