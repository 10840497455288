<script setup lang="ts">
import type { IAuctionItem } from "~/common/interfaces/IMarketplace";
import { computed, onBeforeUnmount, onMounted, type Ref, ref, watch } from "vue";
import { BigNumber } from "ethers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import { useStoreApp } from "~/stores/storeApp";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import {
  AuctionStatus,
  type AuctionUpdate,
  CardType,
  MarketType,
  MercureEventType,
  type NftSkill,
} from "fungi-types";
import { useCardDetails } from "../card-details/use-card-details";
import CardDataFooterScore from "../card/CardDataFooterScore.vue";
import CardDataFooterSkeleton from "../card/CardDataFooterSkeleton.vue";
import CardDataFooterPrice from "../card/CardDataFooterPrice.vue";
import CardDataFooterCtaBuyBidCard from "../card/CardDataFooterCtaBuyBidCard.vue";

import NftSkillCard from "./NftSkill.vue";

dayjs.extend(utc);
dayjs.extend(duration);

const props = withDefaults(
  defineProps<{
    isInteractive?: boolean;
    item: IAuctionItem;
    showCardDetails?: boolean;
    currentlyAvailable?: number;
    showXpBar?: boolean;
    isSecondary?: boolean;
  }>(),
  {
    showCardDetails: true,
    isInteractive: true,
    showXpBar: false,
    currentlyAvailable: -1,
    isSecondary: false,
  }
);

const emits = defineEmits<{
  (event: "buy", data: { item: IAuctionItem; loading: Ref }): void;
  (event: "card-click", data: { card: IAuctionItem["card"] }): void;
}>();

const storeUser = useStoreUserV2();
const timeOver = ref(false);
const loading = ref(false);

const auctionStatus = ref<AuctionStatus>(props.item.status);

const _weiAmount = computed(() => {
  return BigNumber.from(props.item.lastBidAmount ?? props.item.startedAmount);
});

const handleTimeoutEvent = () => {
  timeOver.value = true;
};

function updateAuction(data: AuctionUpdate) {
  if (data.auction.cuid === props.item.cuid) {
    const end = new Date(data.auction.endedAt);

    // check if the auction has ended
    timeOver.value = end.getTime() <= new Date().getTime();

    auctionStatus.value = data.auction.status;
  }
}

function bidOnCard() {
  useStoreMarketplace().selectedItem = props.item;
  useStoreApp().setBidModalState(true);
}

const isOwner = computed(() => {
  if (!storeUser.walletAddress) return false;
  return storeUser.walletAddress === props.item?.card.owner;
});

function buyItem() {
  useStoreMarketplace().selectedItem = props.item;
  useStoreApp().setBidModalState(true);
}

onMounted(() => {
  useStoreMarketplace().emitter.on(MercureEventType.AUCTION_UPDATE, updateAuction);
});

onBeforeUnmount(() => {
  useStoreMarketplace().emitter.off(MercureEventType.AUCTION_UPDATE, updateAuction);
});

watch(
  () => [props.item, auctionStatus],
  () => {
    if (props.item?.status === AuctionStatus.ENDED || auctionStatus.value === AuctionStatus.ENDED) {
      loading.value = false;
    }
  }
);

const cardDetails = useCardDetails();
const navigateToCardDetails = (cardToShowTheDetailsFor: NftSkill) => {
  cardDetails.loadCard(cardToShowTheDetailsFor.tokenId, props.item);
  cardDetails.showModal = true;
};

const handleClickOnCard = () => {
  if (props.showCardDetails && props.item.type !== CardType.NftEquipment) {
    navigateToCardDetails(props.item?.card as NftSkill);
  } else {
    emits("card-click", { card: props.item.card });
  }
};

const xpCalculation = computed(() =>
  props.item.marketType === MarketType.SECONDARY ? props.item.card.xp / 2 : props.item.card.xp
);
</script>

<template>
  <NftSkillCard class="w-fit" :nft="item.card" @click="handleClickOnCard">
    <template #level-info v-if="showXpBar">
      <CardDataFooterSkeleton>
        <XpLevelBar :xp="xpCalculation" />
      </CardDataFooterSkeleton>
    </template>
    <template #footer-data>
      <CardDataFooterSkeleton>
        <template #header>
          <CardDataFooterScore
            v-if="item.type !== CardType.NftEquipment"
            :l10="item.card.score"
            :l5="item.card.l5"
            :court="item.card.courtType"
            :skill="item.card.skill"
            :scarcity="item.card.scarcity"
            :season="item.card.season"
            :xp="xpCalculation"
            :token-id="1"
          />
        </template>

        <CardDataFooterPrice
          v-if="auctionStatus"
          :weiAmount="_weiAmount"
          :marketType="item.marketType"
          :cuid="item.cuid"
          :endedAt="item.endedAt"
          :status="auctionStatus"
          :orders="item?.countOrders"
          :lastBidder="item.lastBidder?.username ?? item.card.ownedBy?.user.username ?? ''"
          @time-out="handleTimeoutEvent"
        />
      </CardDataFooterSkeleton>
    </template>

    <template #cta>
      <cardDataFooterCtaBuyBidCard
        :item="item"
        :is-interactive="isInteractive"
        :is-owner="isOwner"
        :loading="loading"
        :time-over="timeOver"
        :auctionStatus="auctionStatus"
        @buy="buyItem()"
        @bid="bidOnCard"
      />
    </template>
  </NftSkillCard>
</template>

<style scoped lang="scss">
.winner {
  animation: winner 0.6s cubic-bezier(0.87, 0, 0.13, 1);
}

@keyframes winner {
  from {
    scale: 1.66;
    filter: drop-shadow(0 0 4em #ffffff);
  }
  to {
    scale: 1;
    filter: drop-shadow(0 0 0em #ffffff);
  }
}
</style>
