"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatus = exports.Currency = exports.MarketType = exports.GasStationSpeedType = exports.AuctionStatus = void 0;
var AuctionStatus;
(function (AuctionStatus) {
    AuctionStatus["IN_PROGRESS"] = "IN_PROGRESS";
    AuctionStatus["ENDED"] = "ENDED";
    AuctionStatus["CANCELLED"] = "CANCELLED";
    AuctionStatus["DRAFT"] = "DRAFT";
    AuctionStatus["UNLISTED"] = "UNLISTED";
    AuctionStatus["PENDING_TO_BE_EXECUTED"] = "PENDING_TO_BE_EXECUTED";
    AuctionStatus["PENDING_STRIPE_PAYMENT"] = "PENDING_STRIPE_PAYMENT";
})(AuctionStatus || (exports.AuctionStatus = AuctionStatus = {}));
var GasStationSpeedType;
(function (GasStationSpeedType) {
    GasStationSpeedType["SAFE"] = "SAFE";
    GasStationSpeedType["PROPOSE"] = "PROPOSE";
    GasStationSpeedType["FAST"] = "FAST";
})(GasStationSpeedType || (exports.GasStationSpeedType = GasStationSpeedType = {}));
var MarketType;
(function (MarketType) {
    MarketType["PRIMARY"] = "primary";
    MarketType["SECONDARY"] = "secondary";
    MarketType["FLASH_SALE"] = "flashsale";
    MarketType["PRIMARY_EQUIPMENT"] = "primary_equipment";
    MarketType["FLASH_SALE_EQUIPMENT"] = "flashsale_equipment";
    MarketType["SECONDARY_EQUIPMENT"] = "secondary_equipment";
})(MarketType || (exports.MarketType = MarketType = {}));
var Currency;
(function (Currency) {
    Currency["EUR"] = "eur";
    Currency["USD"] = "usd";
    Currency["WMATIC"] = "wmtc";
})(Currency || (exports.Currency = Currency = {}));
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["PENDING"] = "pending";
    OrderStatus["COMPLETED"] = "completed";
    OrderStatus["CANCELLED"] = "cancelled";
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));
