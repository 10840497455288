<script setup lang="ts">
import Trans from "~/modules/translations";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStoreUserV2 } from "~/stores/storeUserV2";
const route = useRoute();

const { t } = useI18n();
const userStore = useStoreUserV2();
const openProfileModal = () => {
  userStore.openProfileDrawer("default");
};
</script>

<template>
  <nav class="nav-container">
    <div class="flex items-center justify-evenly text-xs font-medium text-grey-70">
      <div>
        <router-link :to="Trans.i18nRoute({ name: 'NewCards' })" class="nav-item">
          <div class="icon-container">
            <icon-fg-market />
          </div>
          <span>{{ t("navigation.Market") }}</span>
        </router-link>
      </div>

      <div>
        <router-link :to="Trans.i18nRoute({ name: 'TennisPlayersTable' })" class="nav-item">
          <div class="icon-container">
            <icon-fg-data v-if="route.name !== 'TennisPlayersTable'" />
            <icon-fg-data-active v-else class="stroke-white" />
          </div>
          <span>{{ t("nav.data") }}</span>
        </router-link>
      </div>

      <div>
        <router-link
          :to="Trans.i18nRoute({ name: 'SelectCompetitionMode' })"
          class="nav-item"
          :class="route.name === 'Competitions' ? 'router-link-active' : ''"
        >
          <div class="icon-container">
            <icon-fg-tennis-racket />
          </div>
          <span>{{ t("nav.play") }}</span>
        </router-link>
      </div>

      <div>
        <router-link :to="Trans.i18nRoute({ name: 'MyCards' })" class="nav-item">
          <div class="icon-container">
            <icon-fg-cards />
          </div>
          <span>{{ t("navigation.MyDeck") }}</span>
        </router-link>
      </div>

      <button
        v-if="userStore.isWalletConnected"
        tabindex="-1"
        class="nav-item"
        @click="openProfileModal"
      >
        <div class="w-5 h-5 rounded-full mt-1 mb-0.5">
          <img src="/images/avatar.png" alt="avatar" class="object-contain" />
        </div>

        <span class="truncate">{{ t("nav.profile") }}</span>
      </button>
    </div>
  </nav>
</template>

<style scoped>
.router-link-active {
  @apply text-white;
}

.nav-container {
  @apply flex flex-col justify-center lg:hidden fixed  px-f1 bottom-0 left-0 right-0 bg-primary-110 h-[60px] border-t-2 border-white/20;
}

.nav-item {
  @apply flex flex-col gap-0 items-center justify-center;
}

.icon-container {
  @apply w-6 aspect-square flex items-center justify-center;
}

span {
  @apply truncate max-w-full;
}
</style>
