declare global {
  interface Window {
    dataLayer?: any[];
  }
}

type purchasedItem = {
  market: string;
  username: string;
  user_id: string;
  value: BigNumber | number | string;
  currency: string;
  token_id: number;
  auction_id: string;
  nft_type: string;
  payment_method: string;
};
enum GTM_EVENT {
  SUCCESSFUL_BID = "successful_bid",
  SUCCESSFUL_BID_TEST = "successful_bid_test",
  SUCCESSFUL_BUY = "successful_buy",
  USER_REGISTER = "user_registered",
}

import { BigNumber } from "ethers";
import { Currency } from "fungi-types";
import usePOLPrice from "~/stores/POLprice";

export const useTracking = () => {
  const polPrice = usePOLPrice();

  const pushEvent = async (eventName: string, eventData: Record<string, any>): Promise<void> => {
    console.log("eventName", eventName);
    console.log("eventData", eventData);
    return new Promise((resolve) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: eventName,
        ...eventData,
        eventCallback: () => {
          resolve();
        },
      });
    });
  };

  const convertToEUR = (input: purchasedItem) => {
    const newValue = polPrice.weiToCurrency(input.value as BigNumber, Currency.EUR);
    input.value = parseFloat(Number(newValue).toFixed(2));
    input.currency = "EUR";
    return input;
  };

  const trackSuccessfulBid = async (input: purchasedItem): Promise<void> => {
    if (input.currency === "POL") input = convertToEUR(input);
    await pushEvent(GTM_EVENT.SUCCESSFUL_BID, input);
  };

  const trackSuccessfulBidTest = async (input: purchasedItem): Promise<void> => {
    if (input.currency === "POL") input = convertToEUR(input);
    await pushEvent(GTM_EVENT.SUCCESSFUL_BID_TEST, input);
  };

  const trackSuccessfulBuy = async (input: purchasedItem): Promise<void> => {
    if (input.currency === "POL") input = convertToEUR(input);
    await pushEvent(GTM_EVENT.SUCCESSFUL_BUY, input);
  };

  const trackUserRegistration = async (input: {
    username: string;
    email: string;
    referalCode: string;
  }): Promise<void> => {
    await pushEvent(GTM_EVENT.USER_REGISTER, input);
  };

  return {
    pushEvent,
    trackSuccessfulBid,
    trackSuccessfulBidTest,
    trackSuccessfulBuy,
    trackUserRegistration,
  };
};
