<script setup lang="ts">
import Trans from "~/modules/translations";

import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useStoreFlashSale } from "~/stores/storeFlashSale";

import { X } from "lucide-vue-next";
import { useMediaQuery } from "~/composables/useMediaQuery";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const storeFlashSale = useStoreFlashSale();
const { homeFlashSale, bannerImg, showFlashSaleBanner, description } = storeToRefs(storeFlashSale);

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const isMobile = useMediaQuery("(max-width: 800px)");
const translatedDescription = computed(() => {
  return t(`pages.marketplace.flashSale.banner.${description.value}`).replace("\n", "<br>");
});
function goToFlashSale() {
  if (homeFlashSale.value?.name) {
    router.push(
      Trans.i18nRoute({ name: "FlashSale", params: { name: homeFlashSale.value?.name } })
    );
  }
}

const hideBannerForThesePages = computed(() => {
  return ["FlashSale", "Subscription", "Academy"].includes(route.name as string);
});
</script>

<template>
  <div
    v-if="showFlashSaleBanner && !hideBannerForThesePages"
    :class="`bg-no-repeat hover:cursor-pointer text-xs md:text-lg lg:text-lg bg-[#2c1c24] h-20 flex items-center uppercase cursor-pointer justify-between border-[1px] border-slate-700 w-full z-40`"
    :style="{
      backgroundImage: bannerImg,
      backgroundSize: 'auto 100%',
      backgroundPosition: 'center left',
    }"
    @click="goToFlashSale"
  >
    <div
      class="flex flex-col mx-auto text-shadow font-light font-display tracking-widest lg:font-normal lg:tracking-normal"
      :class="isMobile ? 'text-left pl-4 pr-3' : 'text-center'"
    >
      <span
        class="text-shadow max-w-[600px] whitespace-normal"
        v-html="translatedDescription"
      ></span>
    </div>

    <X v-if="!isMobile" class="mr-3" @click.stop="storeFlashSale.hideFlashSaleBanner" />
  </div>
</template>

<style scoped lang="postcss"></style>
