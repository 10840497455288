<script lang="ts" setup>
import { computed, onBeforeMount, Ref, ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useFloating, offset } from "@floating-ui/vue";
import { X, ArrowLeft, ChevronDown, Info } from "lucide-vue-next";

// Router
import router from "~/router";
import Trans from "~/modules/translations";

// Utils
import { useI18n } from "vue-i18n";
import { BigNumber } from "ethers";
import { maticMinAmount } from "~/application/config";
import { getCurrencySymbol } from "~/utils/currency";
import { useTracking } from "~/composables/useGtm";
import { roundUpToDecimals, roundToDecimals } from "~/utils/math";

// Components
import Modal from "@/components/modals/ModalContainer.vue";
import AuctionItem from "~/components/marketplace/AuctionItem.vue";
import useConvert from "~/composables/useConvert";
import CardDataFooterScore from "../card/CardDataFooterScore.vue";
import CardDataFooterSkeleton from "../card/CardDataFooterSkeleton.vue";
import CardDataFooterPrice from "../card/CardDataFooterPrice.vue";
import ModalDeposit from "./ModalDeposit.vue";
import LastBids from "../marketplace/ModalBidRecentBids.vue";
import LastSales from "../marketplace/ModalBidRecentSales.vue";

// Stores
import { NotifType, useStoreApp } from "~/stores/storeApp";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import usePOLPrice from "~/stores/POLprice";
import { useSecondaryMarketplace } from "~/stores/storeSecondaryMarketplace";

// Payments
import { useStripeStore } from "~/composables/useStripe";
import Currency from "~/common/enums/Currency";

// Types
import { MarketType, CardType } from "fungi-types";
import { PaymentMethod } from "~/types/api-auction";

const secondaryMarketplace = useSecondaryMarketplace();

const showCurrencyDropdown = ref(false);
const toggleCurrencyDropdown = () => {
  showCurrencyDropdown.value = !showCurrencyDropdown.value;
};
const reference = ref(null);
const currencyDropdown = ref(null);
const { floatingStyles } = useFloating(reference, currencyDropdown, {
  middleware: [offset(10)],
});

onClickOutside(currencyDropdown, () => {
  showCurrencyDropdown.value = false;
});

const gtm = useTracking();

const { t } = useI18n();
const storeMarketplace = useStoreMarketplace();
const storeUser = useStoreUserV2();
const storeApp = useStoreApp();

const { weiToMatic, maticToWei } = useConvert;
const polPrice = usePOLPrice();

const isLogged = computed(() => storeUser.isWalletConnected);
const marketType = computed(() => storeMarketplace.selectedItem.marketType);
const isPrimaryMarket = computed(
  () => marketType.value === MarketType.PRIMARY || marketType.value === MarketType.FLASH_SALE
);

// Modal title
const title = computed(() => {
  return isPrimaryMarket.value ? t("_payment.place-your-bid") : t("_payment.confirm-your-purchase");
});

// Bid or Buy confirmation Button
const bidOrBuyConfirmation = computed(() => {
  return isPrimaryMarket.value ? t("_payment.confirm-bid") : t("_payment.confirm-buy");
});

// Currency Symbol
const currencySymbol = computed(() => {
  if (paymentMethod.value === PaymentMethod.CREDIT_CARD) {
    return getCurrencySymbol(currency.value as Currency);
  } else {
    return Currency.WPOL;
  }
});

// Insufficient Crypto Funds
const insufficientCryptoFunds = computed(() => {
  if (paymentMethod.value !== PaymentMethod.CRYPTO) return false;
  if (bidAmountWpol.value > storeUser.currentWMaticBalance) {
    return true;
  }
  return false;
});

const showModalDeposit = ref(false);
const closeModalDeposit = () => {
  showModalDeposit.value = false;
};
const addFund = () => {
  showModalDeposit.value = true;
};

// Handle Payment Method
const currency = ref<Currency | "wmtc">(storeUser.preferredCurrency ?? Currency.USD);
const paymentMethod = ref<PaymentMethod>(
  currency.value === "wmtc" ? PaymentMethod.CRYPTO : PaymentMethod.CREDIT_CARD
);
const updateCurrency = (value: Currency) => {
  showCurrencyDropdown.value = false;
  // storeUser.updatePreferredCurrency(value);
  currency.value = value;
  polPrice.refreshRates();
};

// Sync the last bid amount with the current item
const lastBidAmount = computed(() => {
  if (paymentMethod.value === PaymentMethod.CREDIT_CARD) {
    return roundToDecimals(
      polPrice.weiToCurrency(storeMarketplace.selectedItem?.lastBidAmount || 0, currency.value),
      2
    );
  } else {
    return weiToMatic(storeMarketplace.selectedItem?.lastBidAmount || 0);
  }
});

// Sync the min amount with the current item
const isBelowMinAmount = computed(() => {
  const resp = {
    isBelow: false,
    minAmount: 0,
  };

  if (paymentMethod.value === PaymentMethod.CREDIT_CARD) {
    resp.minAmount = roundUpToDecimals(
      polPrice.weiToCurrency(minAmountWei.value, currency.value),
      2
    );
    resp.isBelow = bidAmountFiat.value < resp.minAmount;
  } else {
    resp.minAmount = weiToMatic(minAmountWei.value);
    resp.isBelow = bidAmountWpol.value < resp.minAmount;
  }
  return resp;
});

const errorCrypto = ref<null | string>(null);
const errorFiat = ref<null | string>(null);

// initAmounts
const minAmountWei = ref(BigNumber.from(0));
const lastBidAmountWei = ref(BigNumber.from(0));
const bidAmountWpol = ref(0);
const bidAmountFiat = ref(0);
const hasChangedBidAmount = ref(false);
const fiatError = ref(false);

const defaultFiatCurrency = Currency.EUR;

const handlePaymentMethodChange = (method: PaymentMethod) => {
  if (method === PaymentMethod.CREDIT_CARD) updateCurrency(defaultFiatCurrency);
  paymentMethod.value = method;
};

const bidOrBuy = async () => {
  // #1 Check if amount is below the minimum amount
  if (isBelowMinAmount.value.isBelow) {
    const currencyValue =
      paymentMethod.value === PaymentMethod.CREDIT_CARD ? currency.value : Currency.WPOL;
    const msg = t("_payment.bid-amount-to-low", {
      amount: lastBidAmount.value,
      currency: currencyValue.toUpperCase(),
    });
    handleErrorMsg({ message: msg });
    return;
  }

  if (isPrimaryMarket.value) {
    await handleConfirmBid();
  } else if (marketType.value === MarketType.SECONDARY) {
    await handleConfirmBuy();
  }
};

const handleConfirmBuy = async () => {
  storeMarketplace.bidInProgress = true;

  try {
    await secondaryMarketplace.buyItem(storeMarketplace.selectedItem);
  } catch (e: any) {
    handleErrorMsg(e);
  }
  storeMarketplace.bidInProgress = false;
};

const handleConfirmBid = async () => {
  // #1 Check is the user is connected
  if (!storeUser.isWalletConnected) return login();

  // #3 Check if the auction has ended
  const end = new Date(storeMarketplace.selectedItem.endedAt);
  if (end.getTime() <= new Date().getTime()) {
    storeApp.displayNotification(
      NotifType.BID_FAILED,
      storeMarketplace.selectedItem,
      t("errors.auction.timeover")
    );
    return storeApp.setBidModalState(false);
  }

  if (paymentMethod.value === PaymentMethod.CREDIT_CARD) {
    await bidWithStripe();
  } else {
    await bid();
  }
};

const _weiAmount = computed(() => {
  return BigNumber.from(
    storeMarketplace.selectedItem.lastBidAmount ?? storeMarketplace.selectedItem.startedAmount
  );
});

const updateMinAmount = () => {
  lastBidAmountWei.value = storeMarketplace.selectedItem.lastBidAmount
    ? BigNumber.from(storeMarketplace.selectedItem.lastBidAmount)
    : BigNumber.from(storeMarketplace.selectedItem.startedAmount);

  // Increase the last bid amount by 10%
  if (isPrimaryMarket.value) {
    minAmountWei.value = lastBidAmountWei.value.add(lastBidAmountWei.value.div(100).mul(10));
  } else {
    minAmountWei.value = lastBidAmountWei.value;
  }

  polPrice.refreshRates();
  return { minAmountWei: minAmountWei.value, lastBidAmountWei: lastBidAmountWei.value };
};

const initAmounts = () => {
  if (marketType.value === MarketType.SECONDARY) {
    currency.value = Currency.WMATIC;
    paymentMethod.value = PaymentMethod.CRYPTO;
  }

  const { minAmountWei } = updateMinAmount();
  bidAmountWpol.value = roundUpToDecimals(weiToMatic(minAmountWei), 2);
  if (currency.value === Currency.WMATIC) {
    bidAmountFiat.value = roundUpToDecimals(
      polPrice.weiToCurrency(minAmountWei, defaultFiatCurrency),
      2
    );
  } else {
    bidAmountFiat.value = roundUpToDecimals(
      polPrice.weiToCurrency(minAmountWei, currency.value),
      2
    );
  }
};

onBeforeMount(() => {
  initAmounts();
  polPrice.refreshRates();
});

const handleErrorMsg = (error: any) => {
  if (paymentMethod.value === PaymentMethod.CREDIT_CARD) {
    errorFiat.value = error.message;
  } else {
    errorCrypto.value = error.message;
  }
};

async function login() {
  storeApp.setBidModalState(false);
  router.push(Trans.i18nRoute({ name: "Login" })).catch(console.error);
}

async function bid() {
  storeMarketplace.bidInProgress = true;
  errorCrypto.value = null;
  errorFiat.value = null;

  try {
    // #1 Check if the user has enough POL for the bid by requesting the ballance on the WMTc contract
    const allow = await storeUser.allowWmtc(bidAmountWpol.value);
    if (!allow && storeUser.currentMaticBalance < maticMinAmount) {
      handleErrorMsg({ message: t("_payment.insufficient-wpol") });
      return;
    }

    // #2 Check if user has enough POL for the gas fees
    if (storeUser.currentMaticBalance < maticMinAmount) {
      storeMarketplace.bidInProgress = false;
      handleErrorMsg({ message: t("_payment.insufficient-pol-gas-fees") });
      return;
    }

    const bidAmountWei = maticToWei(bidAmountWpol.value);
    await storeMarketplace.bidOnCard(storeMarketplace.selectedItem, bidAmountWei);

    storeApp.setBidModalState(false);
  } catch (error: any) {
    handleErrorMsg(error);
  }
  storeMarketplace.bidInProgress = false;
}

const checkoutRef = ref<HTMLElement>();
const stripe = useStripeStore();

const handleSuccessFulStripeBid = () => {
  const item = storeMarketplace.selectedItem;
  gtm.trackSuccessfulBid({
    value: Math.ceil(bidAmountFiat.value * 100),
    auction_id: item.cuid,
    token_id: item.card?.tokenId,
    currency: currency.value as Currency,
    username: storeUser.username,
    user_id: storeUser.cuid,
    market: item.marketType === MarketType.SECONDARY ? MarketType.SECONDARY : MarketType.PRIMARY,
    nft_type: item.type,
    payment_method: "stripe",
  });

  storeMarketplace.bidInProgress = false;
  storeApp.setBidModalState(false);
};

async function bidWithStripe() {
  if (!checkoutRef.value || isNaN(bidAmountFiat.value) || fiatError.value) {
    return;
  }

  if (BigNumber.from(Math.ceil(bidAmountFiat.value * 100)).gt(Number.MAX_SAFE_INTEGER - 1)) {
    throw new Error("Bid amount too high");
  }

  storeMarketplace.bidInProgress = true;

  await stripe.launchCheckoutSession(
    checkoutRef as Ref<HTMLElement>,
    storeMarketplace.selectedItem.cuid,
    Math.ceil(bidAmountFiat.value * 100),
    currency.value as Currency,
    () => handleSuccessFulStripeBid(),
    () => {
      storeMarketplace.bidInProgress = false;
      polPrice.refreshRates();
      errorCrypto.value = "Something went wrong";
    }
  );
}

function handleStripeModalBackButton() {
  stripe.cancelCheckoutSession();
  storeMarketplace.bidInProgress = false;
}

const emit = defineEmits<{
  (event: "onClose"): void;
}>();

const isInputFocused = ref(false);
const inputRef = ref<HTMLInputElement | null>(null);
function focusInput() {
  inputRef.value?.focus();
}

const handleInput = (e: Event) => {
  const value = (e.target as HTMLInputElement).value;
  if (isNaN(Number(value))) return;
  hasChangedBidAmount.value = true;
  if (paymentMethod.value === PaymentMethod.CREDIT_CARD) {
    bidAmountFiat.value = Number(value);
  } else {
    bidAmountWpol.value = Number(value);
  }
};

const handleKeyPress = (e: KeyboardEvent) => {
  if (e.key === "Enter") {
    // emit("enter", modelValue.value);
  }
};

const payments = computed(() => {
  const methods = [
    {
      method: PaymentMethod.CREDIT_CARD,
      label: t("_payment.credit-card"),
    },
    {
      method: PaymentMethod.CRYPTO,
      label: t("_shared.wallet"),
    },
  ];

  if (marketType.value === MarketType.SECONDARY) {
    return methods.filter((method) => method.method === PaymentMethod.CRYPTO);
  }
  return methods;
});

watch(
  () => storeMarketplace.selectedItem.lastBidAmount,
  () => {
    updateMinAmount();
    if (!hasChangedBidAmount.value) initAmounts();
  }
);

watch(
  () => isBelowMinAmount.value.isBelow,
  () => {
    if (hasChangedBidAmount.value) return;
    initAmounts();
  }
);

const handleCloseModal = () => {
  if (stripe.checkoutSessionInProgress) {
    handleStripeModalBackButton();
  } else if (showModalDeposit.value) {
    // Ignore closing modal if deposit modal is open
  } else {
    console.log("handleCloseModal");
    emit("onClose");
  }
};

const disableSecondaryMarket = computed(() => {
  const users = [
    "cm8372y5g00000fs6ggj2971j",
    "cm7226qct000z0fs64zqn5t4q",
    "cm7kg0dnv00000fs68brc5l6j",
    "cm6tpozba00000fs6882w9zrs",
    "cm89u7fkq00010fs6gcp7cqlq",
    "cm8d53cp500000fs65dv4cf86",
    "cm6s4t1qw00010fs653bzc7ec",
    "clyxj82xl005k0hs68po79gd6",
    "cm5r01imf00380es68x0vfm1p",
  ];
  const userIsMatch = users.includes(storeUser.cuid);
  const isSecondary = marketType.value === MarketType.SECONDARY;
  if (isSecondary && userIsMatch) {
    return true;
  }
  return false;
});
</script>

<template>
  <Modal
    modalClasses="!p-0"
    size="4xl"
    :show="true"
    @on-close="handleCloseModal"
    :show-close-button="false"
  >
    <ModalDeposit v-if="showModalDeposit" :show="showModalDeposit" @onClose="closeModalDeposit" />
    <div
      v-show="stripe.checkoutSessionInProgress"
      class="bg-[#fff] p-4 text-slate-950 rounded-md w-full"
    >
      <div>
        <div class="font-semibold text-2xl text-center flex flex-row justify-between">
          <div>{{ title }}</div>
          <button
            @click="handleStripeModalBackButton"
            class="p-2 rounded-full hover:bg-black-800 bg-black border-[1px] border-neutral-600"
          >
            <X class="h-4 w-4 fill-white text-white" />
          </button>
        </div>
        <OvTicker
          :cuid="storeMarketplace.selectedItem.cuid"
          :end-date="storeMarketplace.selectedItem.endedAt"
          :status="storeMarketplace.selectedItem.status"
          class="self-end text-sm [&_span]:text-neutral-500 stroke-neutral-500"
        />
      </div>
      <div class="stripe-checkout-container pt-2" ref="checkoutRef"></div>
      <div class="flex justify-center items-center pt-4">
        <Button @click="handleStripeModalBackButton" type="secondary">
          <div class="flex gap-2 text-sm items-center">
            <ArrowLeft class="h-4 w-4" />
            <span>
              {{ $t("modals.bid.back") }}
            </span>
          </div>
        </Button>
      </div>
    </div>
    <div v-show="!stripe.checkoutSessionInProgress" class="grid grid-cols-2 md:grid-cols-3">
      <div class="justify-center hidden md:flex">
        <AuctionItem
          :background="false"
          :item="storeMarketplace.selectedItem"
          :is-interactive="false"
          :show-card-details="false"
        />
      </div>
      <div class="flex flex-col gap-4 col-span-2 md:px-10">
        <div class="font-semibold text-2xl text-center pb-10 flex flex-row justify-between pt-5">
          <div>{{ title }}</div>
          <button
            @click.stop="handleCloseModal"
            class="p-2 rounded-full hover:bg-slate-800 bg-black border-[1px] border-slate-600"
          >
            <X class="h-4 w-4" />
          </button>
        </div>

        <div class="flex flex-row gap-4 md:hidden">
          <img class="h-32 object-contain" :src="storeMarketplace.selectedItem.card.image" alt="" />

          <div
            class="bg-neutral-800 items-center justify-center rounded-md w-full gap-2 flex flex-col text-xs"
          >
            <CardDataFooterSkeleton>
              <template #header>
                <CardDataFooterScore
                  v-if="storeMarketplace.selectedItem.card.card_type !== CardType.NftEquipment"
                  :l10="storeMarketplace.selectedItem.card.score"
                  :l5="storeMarketplace.selectedItem.card.l5"
                  :court="storeMarketplace.selectedItem.card.courtType"
                  :skill="storeMarketplace.selectedItem.card.skill"
                  :scarcity="storeMarketplace.selectedItem.card.scarcity"
                  :season="storeMarketplace.selectedItem.card.season"
                  :xp="storeMarketplace.selectedItem.card.xp"
                  :token-id="1"
                />
              </template>

              <CardDataFooterPrice
                :weiAmount="_weiAmount"
                :marketType="storeMarketplace.selectedItem.marketType"
                :cuid="storeMarketplace.selectedItem.cuid"
                :endedAt="storeMarketplace.selectedItem.endedAt"
                :status="storeMarketplace.selectedItem.status"
                :lastBidder="
                  storeMarketplace.selectedItem.lastBidder?.username ??
                  storeMarketplace.selectedItem.card.ownedBy?.user.username ??
                  ''
                "
              />
            </CardDataFooterSkeleton>
          </div>
        </div>

        <div class="flex flex-row justify-between w-full md:w-auto">
          <div class="flex flex-row gap-2 items-center w-full md:w-fit">
            <LastBids
              v-if="isPrimaryMarket && storeMarketplace.selectedItem.countOrders"
              :market-type="marketType"
              :count="storeMarketplace.selectedItem.countOrders"
              :currency-symbol="currencySymbol"
              :payment-method="paymentMethod"
              :currency="currency"
            />
            <div
              v-else-if="marketType === MarketType.SECONDARY"
              class="w-full md:w-fit justify-between rounded-full border-500 border-neutral-900 border px-3 p-1 text-xs flex flex-row items-center gap-2 pr-3"
            >
              {{
                t("_payment.sold-by", {
                  username: storeMarketplace.selectedItem.card.ownedBy?.user?.username,
                })
              }}
            </div>
            <LastSales
              :market-type="marketType"
              :count="storeMarketplace.selectedItem.countOrders"
              :currency-symbol="currencySymbol"
              :payment-method="paymentMethod"
              :currency="currency"
              :card="storeMarketplace.selectedItem.card"
            />
          </div>
          <div
            class="w-fit rounded-full border-500 border-neutral-900 border px-3 p-1 hidden md:block"
          >
            <OvTicker
              :cuid="storeMarketplace.selectedItem.cuid"
              :end-date="storeMarketplace.selectedItem.endedAt"
              :status="storeMarketplace.selectedItem.status"
              class="self-end text-xs [&_span]:text-neutral-200"
            />
          </div>
        </div>
        <div class="h-[1px] w-full bg-neutral-800"></div>
        <div class="grid 2 w-full gap-4" :class="`grid-cols-${payments.length}`">
          <div
            v-for="payment in payments"
            :key="payment.method"
            @click="handlePaymentMethodChange(payment.method)"
            class="border transition-colors p-3 text-sm text-neutral-200 border-neutral-700 rounded-md flex flex-col gap-1 cursor-pointer"
            :class="[
              isPrimaryMarket && 'hover:border-blue-700',
              paymentMethod === payment.method &&
                isPrimaryMarket &&
                '!border-blue-700 !bg-blue-950/70 ',
            ]"
          >
            <icon-fg-credit-card
              v-if="payment.method === PaymentMethod.CREDIT_CARD"
              class="w-6 h-6 fill-white"
            />
            <icon-fg-w-pol-square v-else class="w-6 h-6 fill-white" />

            <div class="flex text-xs flex-row flex-wrap md:text-sm w-full">
              <div class="pr-2">
                {{ payment.label }}
              </div>

              <div
                class="flex flex-nowrap flex-row text-yellow-500 gap-0.5"
                v-if="payment.method === PaymentMethod.CRYPTO && isLogged"
              >
                <div>{{ storeUser.availableWMaticBalance.toFixed(2) }}</div>
                <div>WPOL</div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="insufficientCryptoFunds && isLogged"
          class="bg-yellow-400/20 rounded-md flex w-full justify-between p-2 items-center font-medium"
        >
          <div class="text-xs text-yellow-400 flex flex-row gap-2 items-center">
            <Info class="h-4 w-4" /> {{ t("_payment.insufficient-funds") }}
          </div>
          <Button @click="addFund" size="xs" type="primary-white"
            ><span class="font-semibold">{{ t("_payment.add-funds") }}</span></Button
          >
        </div>
        <div class="flex flex-col gap-4">
          <div
            @click.stop="focusInput"
            class="border border-transparent transition-colors px-3 py-4 gap-1 items-center text-neutral-200 bg-neutral-800 cursor-text rounded-md flex group"
            :class="[
              isInputFocused && '!border-blue-700 ',
              isBelowMinAmount.isBelow && '!border-red-800',
              isPrimaryMarket && 'hover:border-blue-700/50',
              marketType === MarketType.SECONDARY && 'hover:cursor-not-allowed',
            ]"
          >
            <div class="flex flex-row items-center gap-2">
              <icon-fg-credit-card
                v-if="paymentMethod === PaymentMethod.CREDIT_CARD"
                class="w-5 h-5 fill-white"
              />
              <icon-fg-w-pol-square v-else class="w-5 h-5 fill-white" />
              <div class="text-sm">{{ t("_payment.amount") }}</div>
            </div>
            <input
              ref="inputRef"
              @keypress="handleKeyPress"
              @focus="isInputFocused = true"
              @blur="isInputFocused = false"
              @input="handleInput"
              min="0"
              class="text-right font-semibold"
              :class="[marketType === MarketType.SECONDARY && 'group-hover:cursor-not-allowed']"
              :value="paymentMethod === PaymentMethod.CREDIT_CARD ? bidAmountFiat : bidAmountWpol"
              data-v-c3ac74de=""
              type="number"
              :disabled="storeMarketplace.bidInProgress || marketType === MarketType.SECONDARY"
            />

            <div
              ref="currencyDropdown"
              :style="[floatingStyles, { visibility: showCurrencyDropdown ? 'visible' : 'hidden' }]"
              class="bg-white text-neutral-800 p-1 rounded-md z-50 flex flex-col gap-1"
            >
              <div
                v-for="item in [Currency.EUR, Currency.USD]"
                :key="item"
                class="hover:bg-blue-700 hover:text-white rounded-md p-2 cursor-pointer"
                :class="[item === currency ? 'bg-blue-700 text-white' : '']"
                @click="updateCurrency(item)"
              >
                {{ item.toUpperCase() }}
              </div>
            </div>

            <div
              @click.stop="toggleCurrencyDropdown"
              ref="reference"
              v-if="paymentMethod === PaymentMethod.CREDIT_CARD"
              class="font-medium border-l border-neutral-700 pl-2 ml-1 flex flex-row items-center gap-1 cursor-pointer group"
            >
              {{ currencySymbol }}
              <ChevronDown class="w-4 h-4 text-neutral-500 group-hover:text-white" />
            </div>
            <div v-else class="font-medium ml-1">
              <icon-fg-w-pol class="w-4 h-4" />
            </div>
          </div>

          <div class="h-[1px] w-full bg-neutral-800"></div>
          <Button
            :loading="storeMarketplace.bidInProgress"
            @click="bidOrBuy"
            type="primary-blue"
            class="w-full"
            :class="[disableSecondaryMarket && 'cursor-not-allowed']"
            :disabled="disableSecondaryMarket"
          >
            <template v-if="isLogged">
              {{ bidOrBuyConfirmation }}
            </template>
            <template v-else>
              {{ t("nav.login") }}
            </template>
          </Button>

          <div
            v-if="errorCrypto && paymentMethod === PaymentMethod.CRYPTO"
            class="border-red-600/2 bg-red-950 text-red-400 text-sm p-2 rounded-md text-center"
          >
            {{ errorCrypto }}
          </div>
          <div
            v-else-if="errorFiat && paymentMethod === PaymentMethod.CREDIT_CARD"
            class="border-red-600/2 bg-red-950 text-red-400 text-sm p-2 rounded-md text-center"
          >
            {{ errorFiat }}
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
input {
  @apply border-transparent bg-transparent outline-none w-full;
  box-shadow: none;
  border-radius: 0;
  &::placeholder {
    @apply text-neutral-500;
  }
}
</style>
