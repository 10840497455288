<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { Notification, useNotificationCenter } from "~/stores/storeNotificationCenter";
import { intervalToDuration } from "date-fns";
import { formatDate, getRelativeTimeDisplay } from "~/utils/dates";
import { BadgeCheck } from "lucide-vue-next";

const props = defineProps<{
  notification: Notification;
}>();

const isRead = props.notification.status === "READ";

const notificationCenter = useNotificationCenter();

const timeElapsed = ref(
  getRelativeTimeDisplay(
    intervalToDuration({
      start: props.notification.createdAt,
      end: new Date(),
    })
  )
);

const publicationDate = computed(() => formatDate(props.notification.createdAt, "PPPppp"));

let timeElapsedInterval: NodeJS.Timeout;

onBeforeMount(() => {
  timeElapsedInterval = setInterval(() => {
    timeElapsed.value = getRelativeTimeDisplay(
      intervalToDuration({
        start: new Date(props.notification.createdAt),
        end: new Date(),
      })
    );
  }, 60_000);
});

onBeforeUnmount(() => {
  clearInterval(timeElapsedInterval);
});
</script>

<template>
  <div
    class="p-1 border-b border-slate-800 cursor-default"
    :class="{
      'bg-slate-900 hover:bg-slate-800': !isRead,
      'bg-slate-950 hover:bg-slate-800': isRead,
    }"
    :data-status="notification.status"
    @click.stop="notificationCenter.markAsRead(notification.id)"
  >
    <div class="px-4 py-5 h-full w-full flex flex-row justify-start items-stretch relative">
      <div v-if="notification.image">
        <img
          :src="notification.image"
          :alt="notification.title"
          class="min-w-6 w-6 rounded-lg mr-4"
        />
      </div>
      <BadgeCheck v-else class="w-6 h-8 min-h-6 min-w-6 mr-2 -mt-1.5" stroke-width="1.25" />

      <div class="grow flex flex-col items-stretch justify-start text-sm gap-2">
        <div class="w-full justify-between flex flex-row items-start">
          <span class="font-medium">{{ notification.title }}</span>
          <div class="flex flex-row gap-2 items-center">
            <Tooltip :text="publicationDate">
              <span class="text-xs italic font-medium text-slate-500 text-nowrap">{{
                timeElapsed
              }}</span>
            </Tooltip>
            <div
              :class="{
                'w-2 h-2 bg-primary-60 rounded-full': !isRead,
              }"
            ></div>
          </div>
        </div>

        <div class="text-xs flex flex-col gap-1">
          <p class="font-normal grow text-slate-400" v-html="notification.description"></p>
          <a
            v-if="notification.link && notification.buttonText"
            class="text-primary-40 hover:underline underline-offset-2 cursor-pointer"
            :href="notification.link"
            target="_blank"
            @click.stop="notificationCenter.markAsRead(notification.id)"
          >
            {{ notification.buttonText }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
