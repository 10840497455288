<script setup lang="ts">
import { scaledNftImg, ThumbSize } from "~/composables/useThumbs";
import { computed, ref } from "vue";
import { CardType, type NftEquipment, type NftSkill } from "fungi-types";

const props = withDefaults(
  defineProps<{
    nft: NftSkill | NftEquipment;
    background?: boolean;
  }>(),
  {
    background: true,
  }
);

const emits = defineEmits<{
  (event: "skill-click", nft: NftSkill): void;
  (event: "equipment-click", nft: NftEquipment): void;
}>();

const nftImage = ref();

function handleCardClick() {
  if (props.nft.card_type === CardType.NftEquipment) {
    emits("equipment-click", props.nft as NftEquipment);
  } else {
    emits("skill-click", props.nft as NftSkill);
  }
}

const IMG = computed(() => {
  // const num = Math.floor(Math.random() * 8) + 1;
  // if (num > 4) return scaledNftImg(props.nft.image, ThumbSize.L);
  // return `http://localhost:5173/images/tmp_cards/card_${num}.png`;
  return scaledNftImg(props.nft.image, ThumbSize.XL);
});
</script>

<template>
  <div
    :class="{ 'card-container': background }"
    class="flex flex-col gap-3 rounded-lg"
    @click.stop="handleCardClick"
  >
    <div class="flex flex-1 flex-col p-4 gap-3 overflow-hidden relative">
      <div class="flex relative justify-center">
        <div class="relative w-fit">
          <image-skeleton
            ref="nftImage"
            :src="IMG"
            :alt="nft?.slug || nft.title"
            :aspect-ratio="0.63"
          />
        </div>
      </div>
      <div class="footer-data">
        <slot name="level-info" />
      </div>
      <div class="footer-data">
        <slot name="footer-data" />
      </div>
      <slot name="cta" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-container {
  @apply transition-all duration-200 ease-in-out;
}
</style>
