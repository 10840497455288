<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import useDynamicSort from "~/composables/useDynamicSort";
import { useCardDetails } from "~/components/card-details/use-card-details";
import DetailsSection from "~/components/card-details/DetailsSection.vue";
import OwnersHistoryTable from "~/components/card-details/OwnersHistory/OwnersHistoryTable.vue";
import OwnersHistoryCard from "~/components/card-details/OwnersHistory/OwnersHistoryCard.vue";

const { t } = useI18n();

const cardDetails = useCardDetails();
const { ownersHistory: ownersHistoryRef, selectedCard } = storeToRefs(cardDetails);

const ownersHistory = useDynamicSort(ownersHistoryRef);
</script>

<template>
  <DetailsSection
    :name="t('cardDetails.cardPreviousOwners.title')"
    v-if="selectedCard?.card.tokenId != null"
  >
    <OwnersHistoryTable class="text-xs" />
  </DetailsSection>
</template>
