import { makeApi } from "./index";

const baseURL = import.meta.env.VITE_API_URL;
const api = makeApi(`${baseURL}`);

export type myFavoriteOnlyResponse = {
  status: string;
  message: string;
  competitorIds: string[];
};

export const getFavoriteTennisPlayers = async (): Promise<string[]> => {
  const { data } = await api.get("tennis-players/favorite");
  return data;
};

export const addFavoriteTennisPlayers = async (
  competitorIds: string[]
): Promise<myFavoriteOnlyResponse> => {
  const { data } = await api.post("tennis-players/favorite", { competitorIds });
  return data;
};

export const removeFavoriteTennisPlayers = async (
  competitorIds: string[]
): Promise<{ data: myFavoriteOnlyResponse; status: number }> => {
  const { data, status } = await api.delete("tennis-players/favorite", {
    data: { competitorIds },
  });
  return { data, status };
};
