<script setup lang="ts">
import { computed } from "vue";
import { MarketType, AuctionStatus } from "fungi-types";
import { useI18n } from "vue-i18n";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    auctionStatus: AuctionStatus;
    isOwner: boolean;
    item: IAuctionItem;
    loading: boolean;
    isInteractive: boolean;
    timeOver: boolean;
  }>(),
  {
    loading: true,
    isInteractive: true,
  }
);

const disableSecondary = computed(() => {
  return props.loading || props.auctionStatus === AuctionStatus.PENDING_TO_BE_EXECUTED;
});

const emits = defineEmits(["buy", "bid"]);
</script>

<template>
  <div
    class="inset-0 absolute flex items-center justify-center overflow-hidden"
    :class="auctionStatus === AuctionStatus.ENDED ? 'flex winner' : 'hidden'"
  >
    <div
      class="bg-darker-blury w-full items-center justify-center flex winner p-3 text-center font-display border-y border-white pointer-events-none uppercase"
    >
      <span v-if="item.marketType === MarketType.SECONDARY">
        {{ $t("pages.marketplace.trade.sold") }}
      </span>
      <span v-else>
        {{ t("pages.marketplace.NewCards.owned-by", { s: item.lastBidder?.username ?? "" }) }}
      </span>
    </div>
  </div>
  <div v-if="isInteractive" class="font-display w-full flex" @click.stop>
    <template v-if="item.marketType === MarketType.SECONDARY">
      <ButtonPrimary v-if="isOwner" disabled class="btn-primary w-full py-f2">
        {{ $t("pages.marketplace.trade.my-card") }}
      </ButtonPrimary>
      <ButtonPrimary v-else-if="auctionStatus === AuctionStatus.ENDED" disabled class="w-full">
        {{ $t("pages.marketplace.NewCards.ended") }}
      </ButtonPrimary>
      <ButtonPrimary
        v-else
        @click="emits('buy')"
        class="w-full capitalize"
        :disabled="disableSecondary"
      >
        {{ $t("pages.marketplace.trade.buy") }}
      </ButtonPrimary>
    </template>
    <template v-else-if="auctionStatus">
      <ButtonPrimary
        v-if="auctionStatus === AuctionStatus.PENDING_TO_BE_EXECUTED"
        disabled
        class="w-full capitalize"
      >
        <icon-fgc-loading />
      </ButtonPrimary>
      <ButtonPrimary
        v-if="timeOver || [AuctionStatus.ENDED, AuctionStatus.CANCELLED].includes(auctionStatus)"
        disabled
        class="w-full capitalize"
      >
        {{ $t("pages.marketplace.NewCards.ended") }}
      </ButtonPrimary>
      <ButtonPrimary v-else class="w-full capitalize" @click="emits('bid')" :disabled="timeOver">
        {{ $t("pages.marketplace.NewCards.bid-now") }}
      </ButtonPrimary>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
