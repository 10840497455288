import { ref } from "vue";
import { defineStore } from "pinia";
import Currency from "~/common/enums/Currency";
import { makeApi } from "~/api";
import { BigNumber } from "ethers";
import useConvert from "~/composables/useConvert";
import { sub } from "date-fns";
import { MercureService } from "~/services/mercureService";
import { MercureEventType, NewPolRates } from "fungi-types";

const api = makeApi(`${import.meta.env.VITE_API_URL}/pol-price`);

const usePOLPrice = defineStore("POLPrice", () => {
  const maxRateTimeoutS = 30;
  const maxRateTimeoutMs = maxRateTimeoutS * 1_000;

  const { weiToMatic } = useConvert;
  const lastUpdateTime = ref<Date>(sub(new Date(), { seconds: maxRateTimeoutS }));
  const rates = ref<NewPolRates>({
    [Currency.EUR]: 0,
    [Currency.USD]: 0,
  });

  const refreshInterval = ref<NodeJS.Timeout | null>(null);

  async function refreshRates() {
    try {
      const { data } = await api.get("/");

      lastUpdateTime.value = new Date();
      rates.value = data;
    } catch (error) {
      console.error("Error fetching POL rates:", error);
    }
  }

  // Immediately refresh the rates
  refreshRates().catch(console.error);

  refreshInterval.value = setInterval(refreshRates, maxRateTimeoutMs);

  // Subscribe to receive real-time updates on POL rates
  MercureService.getInstance().emitter.on(MercureEventType.NEW_POL_RATES, (event: NewPolRates) => {
    rates.value = event;
    lastUpdateTime.value = new Date();

    // Ensure we get the latest rates manually if we don't receive new events in that time frame
    clearInterval(refreshInterval.value ?? undefined);
    refreshInterval.value = setInterval(refreshRates, maxRateTimeoutMs);
  });

  function weiToCurrency(wei: BigNumber, currency: Currency) {
    return weiToMatic(wei) * rates.value[currency];
  }

  function currencyToPol(value: number, currency: Currency) {
    if (rates.value[currency] === 0) return 0;

    return value / rates.value[currency];
  }

  function currencyToWei(value: number, currency: Currency) {
    return weiToMatic(currencyToPol(value, currency));
  }

  return { rates, lastUpdateTime, refreshRates, weiToCurrency, currencyToPol, currencyToWei };
});

export default usePOLPrice;
