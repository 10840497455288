import { makeApi } from "~/api/index";
import { EPreferredLanguage, PreferredLanguage } from "fungi-types";
import Currency from "~/common/enums/Currency";

export interface IRegisterMessageRequest {
  username: string;
  email: string;
  address: string;
  optin: boolean;
}

export interface IRegisterUser {
  username: string;
  email: string;
  optin: boolean;
}

export interface IRegisterRequest {
  username: string;
  email: string;
  optin: boolean;
  referralCode?: string;
  preferredLanguage: EPreferredLanguage;
  utmData?: Record<string, any>;
}

export interface IUser {
  username: string;
  cuid: string;
  email: string;
  referralCode: string;
  referralLevel: number;
  currentWallet: IUserWallet;
  optin: boolean;
  tokenBalance: number;
  preferredLanguage: PreferredLanguage;
  preferredCurrency: Currency;
  showOnboarding: boolean;
}

export interface IUserWallet {
  address: string;
  spendAmount: number;
}

export interface IToken {
  type: string;
  token: string;
  jwt: string;
  expires_at: string;
}

export interface ILoginMessageRequest {
  address: string;
}

export interface ILoginRequest {
  appPublicKey: string;
  idToken?: string;
}

interface ICheckEmailRequest {
  email: string;
}

interface ICheckEmailResponse {
  emailExists: boolean;
}

const baseURL = import.meta.env.VITE_API_URL;
const api = makeApi(baseURL);

export const authApi = {
  async getLoginMessage(req: ILoginMessageRequest): Promise<{ message: string }> {
    const { data } = await api.post(`auth/loginMessage`, req);
    return data.data;
  },
  login: async (req: ILoginRequest): Promise<IToken> => {
    const { data } = await api.post("auth/login", req);
    return data as IToken;
  },
  checkEmail: async (req: ICheckEmailRequest): Promise<ICheckEmailResponse> => {
    const { data } = await api.get("auth/checkEmail", { params: req });
    return data as ICheckEmailResponse;
  },
  async getUserInfo(): Promise<IUser> {
    const { data } = await api.get("user/me");
    return data;
  },
  async getRegisterMessage(req: IRegisterMessageRequest): Promise<{ message: string }> {
    const { data } = await api.post(`auth/registerMessage`, req);
    return data.data;
  },
  async register(body: IRegisterRequest): Promise<IToken> {
    const { data } = await api.post("auth/register", body);
    return data;
  },
};

export const usersApi = {
  async setUserPreferredLanguage(lang: PreferredLanguage) {
    const { data } = await api.put("user/me/language", { preferredLanguage: lang });
    return data;
  },
  async setUserPreferredCurrency(currency: Currency | "wmtc") {
    const { data } = await api.put("user/me/currency", { preferredCurrency: currency });
    return data;
  },
};
