import { defineStore } from "pinia";
import { userApi } from "~/api/user";
import type { CardDeck, IUserDeck, IUserEquipment } from "~/common/interfaces/IUser";
import { MarketplaceApi } from "~/api/marketplace";
import type { IAuctionItem, IMetaData, IPagination } from "~/common/interfaces/IMarketplace";
import mitt from "mitt";
import type { ITransaction } from "~/common/interfaces/ITransaction";
import { CardType, MarketType, NftType } from "fungi-types";
import { type RemovableRef, useLocalStorage } from "@vueuse/core";
import { useRouter } from "vue-router";
import { useStoreUserV2 } from "./storeUserV2";
import type { IMyDeckFilter } from "~/common/interfaces/IMyDeck";

export const useStoreDeck = defineStore("deck-store", {
  state: () => ({
    marketplaceApi: new MarketplaceApi(MarketType.SECONDARY),
    items: [] as IUserDeck["data"],
    equipmentItems: [] as IUserEquipment["data"],
    meta: {} as IUserDeck["meta"],
    identifier: null as string | null, // Wallet address
    loading: false,
    selected: {} as CardDeck,
    filters: {},
    activities: [] as ITransaction[],
    metaActivities: {} as IMetaData,
    loadingActivities: false,
    emitter: mitt(),
    f2pDeckId: "",
    type: useLocalStorage("deckNftType", NftType.SKILL) as RemovableRef<NftType>,
  }),
  getters: {
    activeFilters(state) {
      return Object.values(state.filters).reduce((count: number, value) => {
        if ((typeof value === "string" || Array.isArray(value)) && value.length > 0) {
          return count + 1;
        }
        return count;
      }, 0);
    },
  },

  actions: {
    async getUserDeck(walletOwner: string, pagination?: IPagination) {
      this.loading = true;
      const filters =
        this.type === NftType.F2P
          ? { ...this.filters, f2p: true, deckId: this.f2pDeckId }
          : this.filters;
      const response = await userApi.getUserDeck(walletOwner, filters, this.type, pagination);
      this.items = response.data;
      this.meta = response.meta;
      this.identifier = walletOwner;
      this.loading = false;
      return this.items;
    },
    async getUserEquipment(walletOwner: string, pagination?: IPagination) {
      const response = await userApi.getUserEquipment(walletOwner, this.filters, pagination);
      this.equipmentItems = response.data;
    },
    setType(newType: NftType) {
      this.type = newType;
    },

    setF2pDeckId(deckId: string) {
      this.f2pDeckId = deckId;
    },

    updateItem(tokenId: number, data: Partial<CardDeck>) {
      let index = -1;
      if (!data.card_type || data.card_type === CardType.NftSkills) {
        index = this.items.findIndex((item) => item.tokenId === tokenId);
        this.items[index] = { ...this.items[index], ...data } as CardDeck;
      }
    },

    sendTologin() {
      const router = useRouter();
      router.push({ name: "Login" });
    },

    updateItemAuction(tokenId: number, data: Partial<IAuctionItem>) {
      this.$patch((state) => {
        const item = state.items.find((item) => item.tokenId === tokenId);
        if (!item || !item.auction) return;
        const { status } = data;
        if (status) Object.assign(item.auction, { status });
      });
    },

    async nextPage() {
      this.loading = true;
      if (this.meta.next_page_url && this.identifier) {
        const filters =
          this.type === NftType.F2P
            ? { ...this.filters, f2p: true, deckId: this.f2pDeckId }
            : this.filters;
        const response = await userApi.getUserDeck(this.identifier, filters, this.type, {
          page: this.meta.current_page + 1,
          per_page: this.meta.per_page,
        });
        if (this.type === NftType.SKILL || this.type === NftType.F2P) {
          this.items.push(...response.data);
          const uniqueTokenIds = new Set<number>();
          this.items = this.items.filter((item) => {
            if (uniqueTokenIds.has(item.tokenId)) return false;
            uniqueTokenIds.add(item.tokenId);
            return true;
          });
        }
        this.meta = response.meta;
      }
      this.loading = false;
    },

    async unList(cuid: IAuctionItem["cuid"], tokenId: number, cardType: CardType) {
      try {
        const userStore = useStoreUserV2();
        if (userStore.isWalletConnected) {
          await this.marketplaceApi.unlist(cuid);
          this.updateItem(tokenId, { auction: null, card_type: cardType });
        }
      } catch (e) {
        this.sendTologin();
      }
    },

    async getActivities() {
      const userStore = useStoreUserV2();
      if (userStore.isWalletConnected) {
        this.loadingActivities = true;
        const response = await userApi.getActivity();
        [this.activities, this.metaActivities] = [response.data, response.meta];
        this.loadingActivities = false;
      } else {
        this.sendTologin();
      }
    },

    async selectPageActivities(page: number) {
      const userStore = useStoreUserV2();
      if (userStore.isWalletConnected) {
        this.loadingActivities = true;
        const response = await userApi.getActivity({ page });
        [this.activities, this.metaActivities] = [response.data, response.meta];
        this.emitter.emit("activity-page", page);
        this.loadingActivities = false;
      } else {
        this.sendTologin;
      }
    },

    async updateFilters(filters: Partial<IMyDeckFilter>) {
      this.filters = { ...filters };
      await this.getUserDeck(this.identifier ?? "");
    },

    async resetFilters() {
      this.filters = {} as IMyDeckFilter;
      await this.getUserDeck(this.identifier ?? "");
    },
  },
});
