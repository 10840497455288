import {
  format,
  isToday,
  startOfWeek,
  subWeeks,
  endOfWeek,
  isBefore,
  differenceInYears,
  Duration,
  formatDistanceToNow,
} from "date-fns";
import { IDateRange } from "~/common/interfaces/ITradingDashboard";
import { enUS, fr } from "date-fns/locale";
import i18n from "~/modules/i18n";
const { t, locale } = i18n.global;

export const getLastWeeksDateRange = (
  numberOfWeeks: number,
  since: Date = new Date("2024-02-25 12:00:00")
) => {
  const today = new Date();
  const lastWeeksDateRanges: IDateRange[] = [];

  for (let i = 0; i < numberOfWeeks; i++) {
    const startDate = startOfWeek(subWeeks(today, i), { weekStartsOn: 1 });
    if (isBefore(startDate, since)) break;
    const endDate = endOfWeek(subWeeks(today, i), { weekStartsOn: 1 });
    lastWeeksDateRanges.push({
      from: startDate,
      to: endDate,
    });
  }

  return lastWeeksDateRanges;
};

export const getWeekDateRange = (date: Date = new Date()) => {
  const startDate = startOfWeek(date, { weekStartsOn: 1 });
  const endDate = endOfWeek(date, { weekStartsOn: 1 });

  return { from: startDate, to: endDate };
};

export const formatDate = (date: string | Date, dateFormat: string = "dd MMM") => {
  let res = enUS;
  switch (locale.value) {
    case "fr":
      res = fr;
      break;
    case "en":
    default:
      res = enUS;
      break;
  }
  return `${format(date, dateFormat, { locale: res })}`;
};

export const getAge = (birthDate: Date | string) => {
  const today = new Date();
  const birthdateObj = new Date(birthDate);
  return differenceInYears(today, birthdateObj);
};

export const formatDateRange = (startDate: Date | string, endDate: Date | string) => {
  let res = enUS;
  switch (locale.value) {
    case "fr":
      res = fr;
      break;
    case "en":
    default:
      res = enUS;
      break;
  }

  const startDay = format(startDate, "d", { locale: res });
  const startMonth = format(startDate, "MMMM", { locale: res });
  const endDay = format(endDate, "d", { locale: res });
  const endMonth = format(endDate, "MMMM", { locale: res });

  if (startMonth === endMonth) {
    return `${startDay} - ${endDay} ${endMonth}`;
  }

  return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
};

export const getDateStatus = (dateString: string, returnText: string) => {
  const now = new Date();
  const givenDate = new Date(dateString);

  if (isToday(givenDate)) {
    if (isBefore(now, givenDate)) {
      return returnText;
    }
  }

  if (isBefore(givenDate, now)) {
    // Both date and time are in the past
    return format(givenDate, "dd MMM");
  }

  return "Invalid date";
};

export const getRelativeTimeDisplay = (duration: Duration) => {
  if (!duration) return "";

  const hours = duration.hours || 0;
  const minutes = duration.minutes || 0;
  const seconds = duration.seconds || 0;
  const days = duration.days || 0;

  if (days) return `${days}${t("time.d", { count: days })}`;

  // Convert duration to total minutes for easier comparison
  const totalMinutes = hours * 60 + minutes;

  // If total time is less than a minute, show 'now'
  if (totalMinutes === 0 && seconds < 30) {
    return t("time.now");
  }

  // If less than an hour, show minutes
  if (totalMinutes < 60) {
    return `${minutes}${t("time.min")}`;
  }

  // If less than a day but more than an hour
  if (totalMinutes < 24 * 60) {
    if (minutes > 0) {
      // Show both hours and minutes
      return `${hours}${t("time.h")} ${minutes}${t("time.min")}`;
    }
    // Show only hours if no minutes
    return `${hours}${t("time.h")}`;
  }
};

export const formatDistanceDateToNow = (date: string | Date, addSuffix = true) => {
  const distance = formatDistanceToNow(date, {
    locale: locale.value === "fr" ? fr : enUS,
    addSuffix,
  });
  return distance;
};
