<script setup lang="ts">
import { useI18n } from "vue-i18n";
import Trans from "~/modules/translations";
const { t } = useI18n();

import { computed, onMounted, toRef } from "vue";
import { useRoute } from "vue-router";
import HeaderSkeleton from "./HeaderSkeleton.vue";
import { useStoreEquipmentDeck } from "~/stores/storeEquipmentDeck";
import DeckEquipmentItem from "~/components/myDeck/DeckEquipmentItem.vue";
import { useStoreApp } from "~/stores/storeApp";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { useUpgradeEquipment } from "~/composables/useUpgradeEquipment";
import { TokenPrice } from "fungi-types";

const storeEquipmentDeck = useStoreEquipmentDeck();
const storeApp = useStoreApp();
const { setSelectedEquipment } = useUpgradeEquipment();

const fungiballEquipments = toRef(storeEquipmentDeck, "fungiballEquipments");
const route = useRoute();

const contents = [
  {
    background: "/images/headers/locker.jpg",
    title: t("pages.myDeck.headers.myCards.title"),
    subtitle: t("pages.myDeck.headers.myCards.description"),
  },
  {
    background: "/images/headers/locker.jpg",
    title: t("pages.myDeck.headers.myCardsForSale.title"),
    subtitle: t("pages.myDeck.headers.myCardsForSale.description"),
  },
  {
    background: "/images/headers/locker.jpg",
    title: t("pages.myDeck.headers.transactions.title"),
    subtitle: t("pages.myDeck.headers.transactions.description"),
  },
  {
    background: "/images/headers/locker-equipment-unlock.jpg",
    title: t("pages.myDeck.headers.myGears.title"),
    subtitle: t("pages.myDeck.headers.myGears.description"),
  },
];

const content = computed(() => {
  switch (route.name) {
    case "MyCards":
      return contents[0];
    case "Listed":
      return contents[1];
    case "Transactions":
      return contents[2];
    case "MyGears":
      return contents[3];
    default:
      return contents[0];
  }
});
const isEquipmentPage = computed(() => route.name === "MyGears");

const canUnlock = () => {
  return useStoreUserV2().tokenBalance >= TokenPrice.UNLOCK;
};

onMounted(async () => {
  await storeEquipmentDeck.getUnlockEquipment();
});
const showUnlockModal = (item: (typeof fungiballEquipments.value)[0]) => {
  setSelectedEquipment(item);
  storeApp.showUnlockEquipmentModal = true;
};

const formatTooltipMessage = (item: (typeof fungiballEquipments.value)[0]) => {
  if (useStoreUserV2().tokenBalance < TokenPrice.UNLOCK) {
    return t("pages.myGears.tooltip.unableToUnlock");
  } else {
    return t("pages.myGears.tooltip.ableToUnlock");
  }
};
</script>

<template>
  <HeaderSkeleton
    :title="content.title"
    :subtitle="content.subtitle"
    :background="content.background"
    :show-header="!isEquipmentPage"
  >
    <template #featuring v-if="isEquipmentPage">
      <div
        class="h-full w-full bg-cover bg-center bg-no-repeat flex justify-center flex-col z-10 relative"
      >
        <div class="flex md:flex-row flex-col mt-32 lg:mt-14 w-full place-items-stretch mb-2">
          <div class="items-center md:flex">
            <div
              class="flex flex-col gap-6 px-10 pt-10 md:pb-20 md:min-w-[400px] lg:min-w-[600px] max-w-[800px] mr-10"
            >
              <div class="text-4xl font-semibold uppercase">
                {{ t("pages.myDeck.headers.myGears.title") }}
              </div>
              <div class="flex flex-col gap-1">
                <div class="text-sm">
                  {{ t("pages.myDeck.headers.myGears.customDescription") }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="place-self-start col-span-1 overflow-y-scroll scrollbar-hide pl-10 md:pl-0 w-full"
          >
            <div class="relative grid grid-flow-col">
              <DeckEquipmentItem
                class="w-56 mr-6"
                v-for="item in fungiballEquipments"
                :key="item.id"
                :item="item"
                :loading="false"
                :to-unlock="true"
                :can-unlock="canUnlock()"
                :is-upgradable="false"
                @show-unlock-modal="showUnlockModal"
                :tooltip-message="formatTooltipMessage(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <router-link
      :to="Trans.i18nRoute({ name: 'MyCards', params: $route.params })"
      class="p-2 px-4 bg-slate-700 border-[1px] border-slate-600 relative z-10 border-b-0"
      >{{ $t("pages.myDeck.myCards") }}</router-link
    >
    <router-link
      :to="Trans.i18nRoute({ name: 'MyGears', params: $route.params })"
      class="p-2 px-4 bg-slate-700 border-[1px] border-slate-600 relative z-10 border-b-0"
      >{{ $t("pages.myDeck.myGears") }}</router-link
    >
    <router-link
      :to="Trans.i18nRoute({ name: 'Transactions' })"
      class="p-2 px-4 bg-slate-700 border-[1px] border-slate-600 relative z-10 border-b-0"
      >{{ $t("pages.myDeck.transactions") }}</router-link
    >
  </HeaderSkeleton>
</template>

<style scoped>
.router-link-exact-active {
  @apply !border-slate-600 !bg-black transition-colors;
}
</style>
