import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import type { AppRouter } from "svc-auction";
import { userToken } from "~/utils/localStorage";
const baseUrl = import.meta.env.VITE_AUCTION_API_URL;

export const auctionApi = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: baseUrl,
      async headers() {
        return {
          authorization: "Bearer " + userToken.get(),
        };
      },
    }),
  ],
});
