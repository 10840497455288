<script setup lang="ts">
import { X } from "lucide-vue-next";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const emit = defineEmits(["onClose", "onGoBack"]);
import { computed, onMounted, onUnmounted, ref } from "vue";

const props = withDefaults(
  defineProps<{
    title?: string;
    show: boolean;
    headerIMG?: string;
    size?: "sm" | "md" | "lg" | "full" | "2xl" | "4xl" | "5xl" | "6xl" | number;
    bgColor?: string;
    loading?: boolean;
    modalClasses?: string;
    closeButtonClasses?: string;
    showCloseButton?: boolean;
    showGoBackButton?: boolean;
  }>(),
  {
    loading: false,
    showGoBackButton: false,
    showCloseButton: true,
  }
);

const bgColor = props.bgColor || "bg-gray-950";
const padding = "px-5";

const handleOnClose = () => emit("onClose");
const handleOnGoBack = () => emit("onGoBack");

const computedSize = computed(() => {
  const defaultSize = "max-w-lg";
  if (!props.size) return defaultSize;
  else if (typeof props.size === "number") return `max-w-[${props.size}px]`;
  else return `max-w-${props.size}`;
});

const shoModal = ref(false);

onMounted(() => {
  shoModal.value = true;
  document.body.classList.add("overflow-hidden");
});

onUnmounted(() => {
  document.body.classList.remove("overflow-hidden");
});
</script>

<template>
  <teleport to="body">
    <div
      v-if="show"
      class="fixed z-50 w-full overflow-y-auto flex justify-center pb-0 md:pb-2 p-2 inset-0"
    >
      <div class="fixed inset-0 bg-slate-400/30 backdrop-blur-sm"></div>
      <transition name="slide-fade">
        <div
          v-if="shoModal"
          class="flex flex-col items-stretch w-full z-0 justify-end md:justify-center relative"
          :class="computedSize + ' ' + modalClasses"
        >
          <div class="fixed inset-0" @click.self="handleOnClose"></div>
          <div
            :class="`${bgColor} ${headerIMG ? 'p-1' : ''} ${$slots.footer ? '' : 'sm:rounded-b-xl'} overflow-auto h-fit rounded-t-xl relative pb-5`"
          >
            <div v-if="loading" class="absolute inset-0 overflow-hidden">
              <Loader class="z-50 bg-black/90 backdrop-blur-sm" />
            </div>
            <button
              v-if="showCloseButton"
              @click="handleOnClose"
              class="p-2 absolute top-4 right-4 rounded-full hover:bg-slate-800 bg-black border-[1px] border-slate-600 hidden sm:block"
              :class="closeButtonClasses"
            >
              <X class="h-5 w-5" />
            </button>

            <button
              v-if="showGoBackButton"
              @click="handleOnGoBack"
              class="p-2 absolute top-4 left-4 rounded-full hover:bg-slate-800 bg-black border-[1px] border-neutral-600 hidden sm:block text-sm px-4"
              :class="closeButtonClasses"
            >
              <div class="flex flex-row gap-2 items-center">
                <icon-fg-back-arrow class="h-4 w-4" /> {{ t("back") }}
              </div>
            </button>
            <div class="flex flex-col w-full gap-5">
              <div
                v-if="headerIMG"
                :style="{ backgroundImage: 'url(' + headerIMG + ')' }"
                class="bg-cover bg-center h-40 top-2 left-2 right-2 rounded-t-xl border-b-[1px] border-neutral-800"
              ></div>

              <div
                v-if="title"
                class="text-xl md:text-2xl font-semibold py-5"
                :class="`${padding} ${showCloseButton ? 'pr-16' : ''}`"
              >
                {{ title }}
              </div>
              <div :class="[padding, title ? '' : 'pt-5']">
                <slot></slot>
              </div>
            </div>
          </div>
          <div
            :class="`border-neutral-800 border-t-[1px] ${$slots.footer ? '' : 'sm:border-none'}`"
          >
            <div v-if="$slots.footer" :class="`${bgColor} p-2 sm:rounded-b-xl relative`">
              <slot name="footer"> </slot>
            </div>
            <div
              v-if="showCloseButton || showGoBackButton"
              :class="`w-full  sm:hidden text-sm  text-center ${bgColor} rounded-b-xl flex w-full justify-between divide-x-2 divide-zinc-800`"
            >
              <button
                v-if="showGoBackButton"
                @click="handleOnGoBack"
                class="hover:bg-neutral-800 w-full p-4"
              >
                {{ t("back") }}
              </button>
              <button
                v-if="showCloseButton"
                @click="handleOnClose"
                class="hover:bg-neutral-800 w-full p-4"
              >
                {{ t("button.close") }}
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(10px);
}

.v-enter-from,
.v-leave-to {
  margin-top: 10px;
  transform: translateY(0%);
}

.body-table {
  border-collapse: separate;
  border-spacing: 0px;
}
.body-table .bg-body td {
  border-spacing: 0px 2px;
  border-color: #333;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
}

.body-table th:last-child {
  text-align: end;
}

.body-table tr.bg-body td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right-width: 1px;
}

.body-table tr.bg-body td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left-width: 1px;
}

.bg-body {
  background-color: #1f272b;
}
</style>
