<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { NftSkill } from "fungi-types";
import { scoreColor } from "./../../composables/color";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    l5: NftSkill["l5"] | null;
  }>(),
  {
    l5: () => [],
  }
);

const { t } = useI18n();

const missingScores = computed(() => {
  const length = props.l5?.length ?? 0;
  return 5 - length;
});
const fallbackScores = computed(() => {
  return Array.from({ length: missingScores.value }, (_, i) => ({
    gameweek: 0 - missingScores.value + i,
    score: null,
    fallback: true,
  }));
});

const l5 = computed(() => {
  if (props.l5) return props.l5;
  return [];
});

const scores: (NftSkill["l5"][0] & { fallback?: boolean })[] = [
  ...fallbackScores.value,
  ...l5.value,
];

const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};
</script>

<template>
  <Tooltip theme="light" :text="t('card.l5.tooltipLegend')">
    <figure
      class="grid grid-cols-5 grid-rows-1 gap-0.5 items-end min-w-6 h-5 bg-neutral-950 p-1 rounded-md"
    >
      <div
        @click.stop="handleClick"
        v-for="{ score, gameweek, fallback } in scores"
        :key="gameweek"
        class="w-0.5 rounded-lg bg-neutral-900 h-full flex flex-col-reverse"
        :data-score="score ?? 'null'"
        :data-gw="gameweek"
        :data-is-fallback="fallback ? 'true' : 'false'"
      >
        <div
          class="w-full rounded-lg"
          :style="{
            backgroundColor: score ? scoreColor(score) : '#4B5563',
            height: `${score ? Math.max(score ?? 30, 30) : 20}%`,
          }"
        ></div>
      </div>
    </figure>
  </Tooltip>
</template>
