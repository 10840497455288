<script setup lang="ts">
import { computed, ref, watch, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useCardDetails } from "~/components/card-details/use-card-details";
import { getCountryNameI18n } from "~/utils/countryIso";
import { getAge } from "~/utils/dates";
import FavoriteFilterContainer from "~/components/data/filters/FavoriteFilterContainer.vue";
import { useMyFavoritesTennisPlayers } from "~/composables/useMyFavoriteTennisPlayers";

const { t, locale } = useI18n();
const cardDetails = useCardDetails();
const { selectedCard } = storeToRefs(cardDetails);
const { favoriteTennisPlayers, removeFavoriteTennisPlayer, addFavoriteTennisPlayer } =
  useMyFavoritesTennisPlayers();
const card = computed(() => selectedCard.value?.card);
const competitorId = computed(() => selectedCard.value?.card.competitorId);

const isPlayerAddedToFavorite = computed(() =>
  favoriteTennisPlayers.value.includes(`${competitorId.value}`)
);

const badges = computed(() => {
  if (!card.value) return [];

  const countryName = getCountryNameI18n(card.value.countryIso!, locale.value);
  const scarcity = t(`scarcities.${card.value.scarcity}`);
  const season = card.value.season.toString();
  const age = `${getAge(card.value.dateOfBirth)} ${t("cardDetails.yearsOld")}`;

  return [countryName, scarcity, season, age].map(
    (badge) => badge.charAt(0).toUpperCase() + badge.slice(1)
  );
});

const handleFavoriteClick = () => {
  if (isPlayerAddedToFavorite.value) {
    removeFavoriteTennisPlayer(`${competitorId.value}`);
  } else {
    addFavoriteTennisPlayer(`${competitorId.value}`);
  }
};
</script>

<template>
  <div class="flex flex-col gap-2 items-start" v-show="card != null">
    <div class="flex flex-row items-center gap-2">
      <h1 class="font-display text-xl md:lg:text-2xl lg:text-4xl uppercase">
        {{ card!.firstName! }} {{ card!.lastName! }}
      </h1>
      <FavoriteFilterContainer
        class="px-0 bg-inherit border-none"
        :fallbackFunc="handleFavoriteClick"
        :isSelected="isPlayerAddedToFavorite"
        fill="#1D4ED8"
        stroke="#1D4ED8"
      />
    </div>
    <ul class="flex flex-row items-center justify-start gap-1 lg:gap-2">
      <li
        v-for="(badge, idx) in badges"
        :key="idx"
        class="bg-white/10 border-slate-600 border rounded-full px-3 py-1 text-xs whitespace-nowrap cursor-default"
      >
        {{ badge }}
      </li>
    </ul>
  </div>
</template>
