import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { Gavel } from "lucide-vue-next";
import { notificationApi } from "~/api/notification";
import { MercureService } from "~/services/mercureService";
import {
  CardType,
  CardWonEventPayload,
  InAppNotificationEventPayload,
  MercureEventType,
  NftEquipment,
  NftSkill,
  NotificationType,
  OutbidEventPayload,
} from "fungi-types";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { nanoid } from "nanoid";
import { usePrimaryMarketplace } from "~/stores/storePrimaryMarketplace";
import { NotifType, useStoreApp } from "~/stores/storeApp";
import { useI18n } from "vue-i18n";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";

enum NotificationStatus {
  READ = "READ",
  SENT = "SENT",
  CREATED = "CREATED",
  FAILURE = "FAILURE",
}

export type Notification = {
  id: string;
  title: string;
  description: string;
  image: string | null;
  link: string | null;
  buttonText: string | null;
  createdAt: Date;
  status: NotificationStatus;
  publicationDate: Date;
  type: NotificationType;
};

const lineBreakRegex = /\n/g;

export const useNotificationCenter = defineStore("notification-center", () => {
  const { t } = useI18n();

  const storeUser = useStoreUserV2();

  const open = ref(false);
  const hideReadNotifications = ref(false);

  const notifications = ref<Notification[]>([]);
  const tempNotifications = ref<Notification[]>([]);

  const allNotifications = computed(() => {
    return [...notifications.value, ...tempNotifications.value].sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
    );
  });

  const unreadNotificationsCount = computed(() => {
    return allNotifications.value.filter((n) => n.status !== NotificationStatus.READ).length;
  });

  MercureService.getInstance().emitter.on(
    MercureEventType.IN_APP_NOTIFICATION,
    async (event: InAppNotificationEventPayload) => {
      notifications.value.push({
        id: event.id,
        title: event.titleFr,
        description: event.descriptionFr,
        image: event.imageFr,
        link: event.linkFr,
        type: NotificationType.IN_APP,
        buttonText: event.buttonTextFr,
        createdAt: new Date(event.createdAt),
        publicationDate: new Date(event.publicationDate),
        status: NotificationStatus.CREATED,
      });

      await showPushNotification({
        title: event.titleFr,
        body: event.descriptionFr,
        image: event.imageFr,
        timestamp: new Date(event.createdAt).getTime(),
        vibrate: [100, 50, 100],
        data: {
          link: event.linkFr,
        },
      });
    }
  );

  MercureService.getInstance().emitter.on(
    MercureEventType.CARD_WON,
    async (event: CardWonEventPayload) => {
      const nftSkill = event.cardType === CardType.NftSkills ? (event.card as NftSkill) : null;
      const nftEquipment =
        event.cardType === CardType.NftEquipment ? (event.card as NftEquipment) : null;

      const notificationTitle = t("messages.bid-win-title");
      const notificationBody = t("messages.bid-win", {
        cardName:
          event.cardType === CardType.NftSkills
            ? `${nftSkill!.firstName} ${nftSkill!.lastName}`
            : `${nftEquipment!.title}`,
        skill: event.cardType === CardType.NftSkills ? nftSkill!.skill : "",
        scarcity: event.cardType === CardType.NftSkills ? nftSkill!.scarcity : "",
      }).replace(/ {2,}/g, " ");

      notifications.value.push({
        id: nanoid(),
        title: notificationTitle,
        description: notificationBody,
        image: event.card.image,
        link: null,
        type: NotificationType.IN_APP,
        buttonText: null,
        createdAt: new Date(event.timestamp),
        publicationDate: new Date(event.timestamp),
        status: NotificationStatus.CREATED,
      });

      useStoreApp().displayNotification(NotifType.WIN, event as unknown as IAuctionItem);

      await showPushNotification({
        title: notificationTitle,
        body: notificationBody,
        image: event.card.image,
        timestamp: new Date(event.timestamp).getTime(),
        vibrate: [100, 50, 100],
      });
    }
  );

  MercureService.getInstance().emitter.on(
    MercureEventType.OUTBID,
    async (event: OutbidEventPayload) => {
      const auction = usePrimaryMarketplace().auctions.find((a) => a.cuid === event.auctionCuid);

      const nftSkill =
        event.card.card_type === CardType.NftSkills ? (event.card as NftSkill) : null;
      const nftEquipment =
        event.card.card_type === CardType.NftEquipment ? (event.card as NftEquipment) : null;

      const notificationTitle = t("messages.outbid-notification-title", "You've been outbid");
      const notificationBody =
        nftSkill !== null
          ? t("messages.outbid", {
              cardName: `${nftSkill.firstName} ${nftSkill.lastName}`,
              skill: nftSkill.skill,
              scarcity: nftSkill.scarcity,
            })
          : t("messages.outbid-equipment", {
              cardName: nftEquipment!.title,
            });

      notifications.value.push({
        id: nanoid(),
        title: notificationTitle,
        description: notificationBody,
        image: event.card.image,
        link: null,
        type: NotificationType.IN_APP,
        buttonText: null,
        createdAt: new Date(event.timestamp),
        publicationDate: new Date(event.timestamp),
        status: NotificationStatus.CREATED,
      });

      if (auction) {
        useStoreApp().displayNotification(NotifType.OUTBID, auction);
      } else {
        console.error(`Auction ${event.auctionCuid} not found in primary marketplace`);
      }

      await showPushNotification({
        title: notificationTitle,
        body: notificationBody,
        image: event.card.image,
        timestamp: new Date(event.timestamp).getTime(),
        vibrate: [100, 50, 100],
      });
    }
  );

  MercureService.getInstance().emitter.on(MercureEventType.REFRESH_NOTIFICATIONS, () => {
    console.info(`Refreshing notifications`);
    fetchNotifications().catch(console.error);
  });

  async function requestPushNotificationsPermission(): Promise<typeof Notification.permission> {
    return await Notification.requestPermission();
  }

  async function showPushNotification(params: NotificationOptions & { title: string }) {
    const shouldShowPushNotification = (await requestPushNotificationsPermission()) === "granted";

    const { title, ...otherParams } = params;

    if (shouldShowPushNotification) {
      new Notification(title, otherParams);
    }
  }

  setInterval(() => {
    if (!storeUser.isWalletConnected) return;

    fetchNotifications().catch(console.error);
  }, 5 * 60_000);

  async function fetchNotifications() {
    notifications.value = (
      await notificationApi.inApp.list.query({
        showRead: !hideReadNotifications.value,
      })
    )
      .map((n) => ({
        ...n,
        description: n.description.replace(lineBreakRegex, "<br />"),
        publicationDate: new Date(n.publicationDate),
        createdAt: new Date(n.publicationDate ?? n.createdAt),
      }))
      .filter((n) => {
        return n.publicationDate.getTime() < Date.now();
      });
  }

  async function markAllAsRead() {
    await notificationApi.inApp.markAllAsRead.mutate();

    notifications.value = notifications.value.map((n) => ({
      ...n,
      status: NotificationStatus.READ,
    }));
    tempNotifications.value = tempNotifications.value.map((n) => ({
      ...n,
      status: NotificationStatus.READ,
    }));
  }

  async function markAsRead(id: string) {
    if (notifications.value.some((n) => n.id === id)) {
      await notificationApi.inApp.updateStatus.mutate({
        id,
        status: NotificationStatus.READ,
      });

      notifications.value = notifications.value.map((n) => ({
        ...n,
        status: n.id === id ? NotificationStatus.READ : n.status,
      }));

      console.log(notifications.value);
    } else {
      tempNotifications.value = tempNotifications.value.map((n) => ({
        ...n,
        status: n.id === id ? NotificationStatus.READ : n.status,
      }));
    }
  }

  return {
    open,
    notifications,
    tempNotifications,
    allNotifications,
    hideReadNotifications,
    unreadNotificationsCount,
    requestPushNotificationsPermission,
    fetchNotifications,
    markAllAsRead,
    markAsRead,
  };
});
