<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref, computed } from "vue";
import Modal from "@/components/modals/ModalContainer.vue";
import { useRouter } from "vue-router";
import { onboardingApi } from "~/api/onboarding";
const router = useRouter();
const { t, locale } = useI18n();

import { useStoreUserV2 } from "~/stores/storeUserV2";

const userStore = useStoreUserV2();

const emit = defineEmits<{
  (event: "goBack"): void;
  (event: "onClose"): void;
}>();

const handleCloseModal = () => {
  onboardingApi.hideOnboarding();
  emit("onClose");
};

const handleGoTo = (to: string) => {
  if (to === "Market") {
    router.push({ name: "Marketplace" });
    handleCloseModal();
  } else if (to === "onboarding") {
    currentStep.value = 0;
  } else if (to === "guide") {
    window.open(guideLink.value, "_blank");
  }
};

const props = withDefaults(
  defineProps<{
    show?: boolean;
  }>(),
  {
    show: true,
  }
);

const guideLink = computed(() => {
  const isEn = locale.value === "en" ? true : false;
  if (isEn) return t("https://guide.fungiball.io/en");
  return t("https://guide.fungiball.io");
});

const currentStep = ref(0);

const steps = computed(() => {
  let steps = [
    {
      title: t("onboarding.step1.title", { username: userStore.username }),
      visual: {
        img: "/images/onboardingStep1.jpg",
        alt: "Fungiball",
      },
      paragraphs: [
        {
          txt: t("onboarding.step1.p1"),
        },
        {
          txt: t("onboarding.step1.p2"),
        },
        {
          txt: t("onboarding.step1.p3"),
        },
        {
          txt: t("onboarding.step1.p4"),
        },
      ],
    },
    {
      title: t("onboarding.step2.title"),
      visual: {
        img: "/images/onboardingStep2.jpg",
        alt: "Fungiball",
      },
      paragraphs: [
        {
          txt: t("onboarding.step2.p1"),
        },
        {
          txt: t("onboarding.step2.p2"),
        },
      ],
    },
    {
      title: t("onboarding.step3.title"),
      visual: {
        img: "/images/onboardingStep3.jpg",
        alt: "Fungiball",
      },
      paragraphs: [
        {
          txt: t("onboarding.step3.p1"),
        },
        {
          txt: t("onboarding.step3.p2"),
        },
        {
          txt: t("onboarding.step3.p3"),
        },
      ],
    },
    {
      title: t("onboarding.step4.title"),
      visual: {
        img: "/images/onboardingStep4.jpg",
        alt: "Fungiball",
      },
      paragraphs: [
        {
          txt: t("onboarding.step4.p1"),
        },
        {
          txt: t("onboarding.step4.p2"),
        },
        {
          txt: t("onboarding.step4.p3"),
        },
      ],
    },
    {
      title: t("onboarding.step5.title"),
      titleClass: "!text-center",
      visual: {
        img: "/images/onboardingStep5.jpg",
        alt: "Fungiball",
      },
      paragraphs: [
        {
          txt: "",
          cta: [
            {
              txt: t("onboarding.step5.cta1"),
              type: "primary-blue",
              gotTo: "Market",
            },
          ],
        },
        {
          txt: "",
          cta: [
            {
              txt: t("onboarding.step5.cta2"),
              type: "secondary-outline",
              gotTo: "onboarding",
            },
          ],
        },
        {
          txt: "",
          cta: [
            {
              txt: t("onboarding.step5.cta3"),
              type: "secondary-outline",
              gotTo: "guide",
            },
          ],
        },
      ],
    },
  ];
  return steps;
});

const currentStepData = computed(() => {
  return steps.value[currentStep.value];
});

const handleGoToNextStep = () => {
  if (currentStep.value < steps.value.length - 1) {
    currentStep.value++;
  } else {
    handleCloseModal();
  }
};

const handleGoToPreviousStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--;
  } else {
    handleCloseModal();
  }
};

const progression = computed(() => {
  // let step = currentStep.value === 0 ? 0 : currentStep.value + 1;
  return `${((currentStep.value + 1) / steps.value.length) * 100}%`;
});

const stepEnd = computed(() => {
  return currentStep.value === steps.value.length - 1;
});
</script>

<template>
  <Modal
    bgColor="bg-black"
    modalClasses=""
    :show="true"
    :showCloseButton="false"
    size="lg"
    closeButtonClasses=""
    :headerIMG="currentStepData.visual.img"
  >
    <TransitionGroup name="slide" tag="div" class="relative min-h-[400px]">
      <div
        v-for="(step, index) in steps"
        :key="index"
        v-show="currentStep === index"
        class="step-content w-full"
      >
        <div class="flex flex-col gap-2 w-full">
          <div class="text-3xl mb-3 font-semibold" :class="step?.titleClass">
            {{ currentStepData.title }}
          </div>

          <div v-for="(paragraph, pIndex) in step.paragraphs" :key="pIndex" class="mb-4">
            <p v-if="paragraph.txt" v-html="paragraph.txt"></p>
            <div v-else class="w-full items-center justify-center gap-4 flex flex-col">
              <div class="md:px-24 px-10 w-full mt-2">
                <Button
                  v-for="(cta, cIndex) in paragraph.cta"
                  :key="cIndex"
                  :href="cta.link"
                  target="_blank"
                  :type="cta.type"
                  class="w-full"
                  @click="handleGoTo(cta.gotTo)"
                  rel="noopener noreferrer"
                >
                  {{ cta.txt }}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionGroup>

    <template v-if="!stepEnd" #footer>
      <div class="absolute top-0 left-0 right-0 h-1">
        <div
          :style="{ width: progression }"
          class="bg-blue-600 h-0.5 transition-all duration-300"
        ></div>
      </div>
      <div
        class="w-full items-center gap-4 px-4 flex my-4"
        :class="currentStep === 0 ? 'justify-center' : 'justify-between'"
      >
        <Button v-if="currentStep !== 0" type="secondary-outline" @click="handleGoToPreviousStep">
          {{ t("_shared.previous") }}
        </Button>
        <Button type="primary-blue" @click="handleGoToNextStep">
          {{ currentStep === 0 ? t("onboarding.start") : t("_shared.next") }}
        </Button>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
.step-content {
  position: absolute;
  width: 100%;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-enter-from {
  opacity: 0;
  transform: translateX(100%);
}

.slide-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-enter-to,
.slide-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
