<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SheetContent, SheetTitle } from "~/shadcn/components/ui/sheet";
import { Separator } from "~/shadcn/components/ui/separator";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { ArrowLeft, X } from "lucide-vue-next";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { DrawerContent, DrawerTitle } from "~/shadcn/components/ui/drawer";
import ProfileDrawerFooterButton from "~/components/profile/ProfileDrawerFooterButton.vue";
import { useStoreApp } from "~/stores/storeApp";

const { t } = useI18n();

const userStore = useStoreUserV2();
const app = useStoreApp();

const breakpoints = useBreakpoints(breakpointsTailwind);

const breakpointIsLgOrAbove = breakpoints.greaterOrEqual("lg");

const restartOnboarding = () => {
  app.showOnboardingModal = true;
  userStore.closeProfileDrawer();
};
</script>

<template>
  <component
    :is="breakpointIsLgOrAbove ? SheetContent : DrawerContent"
    class="border-none outline-none p-0 bg-slate-950 text-white h-screen flex flex-col justify-between"
    hide-default-close-button
  >
    <component
      :is="breakpointIsLgOrAbove ? SheetTitle : DrawerTitle"
      class="p-6 flex items-center justify-between"
    >
      <div class="w-full flex flex-row items-center gap-4">
        <ArrowLeft
          class="cursor-pointer"
          aria-label="Back"
          @click="userStore.openProfileDrawer('default')"
        />

        <span class="grow">{{ t("drawers.profile.settings.settings", "Settings") }}</span>

        <X
          class="w-4 cursor-pointer hidden lg:block"
          aria-label="Close"
          @click.stop="userStore.closeProfileDrawer()"
        />
      </div>
    </component>

    <div class="grow flex flex-col items-stretch h-full">
      <Separator class="bg-slate-600" />

      <div class="p-6 flex flex-col justify-start items-stretch gap-6">
        <ProfileDrawerSection
          :title="t('drawers.profile.settingsView.preferences.preferences', 'Preferences')"
        >
          <div class="flex flex-col gap-4 p-4 bg-slate-900 rounded-lg">
            <ProfileDrawerCurrencySwitcher />
            <ProfileDrawerLanguageSwitcher />
          </div>
        </ProfileDrawerSection>

        <ProfileDrawerSection :title="t('drawers.profile.settingsView.onboarding.title')">
          <div class="flex flex-col items-stretch gap-1 bg-slate-900 rounded-lg p-4">
            <button
              tabindex="-1"
              @click.stop="restartOnboarding"
              class="py-2 px-3 grid place-content-center rounded-full bg-neutral-100/20 hover:bg-primary-500"
            >
              {{ t("drawers.profile.settingsView.onboarding.restart") }}
            </button>
            <p class="text-neutral-400 text-xs">
              {{ t("drawers.profile.settingsView.onboarding.desc") }}
            </p>
          </div>
        </ProfileDrawerSection>
      </div>
    </div>

    <ProfileDrawerFooterButton @click="userStore.openProfileDrawer('default')">
      <template #icon>
        <ArrowLeft class="" />
      </template>
      {{ t("drawers.back", "Back") }}
    </ProfileDrawerFooterButton>
  </component>
</template>
