import { ref } from "vue";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import {
  addFavoriteTennisPlayers,
  getFavoriteTennisPlayers,
  removeFavoriteTennisPlayers,
} from "~/api/services";

export const useMyFavoritesTennisPlayers = () => {
  const favoriteTennisPlayers = ref<string[]>([]);
  const userStore = useStoreUserV2();

  const addFavoriteTennisPlayer = async (competitorId: string) => {
    try {
      favoriteTennisPlayers.value = [...favoriteTennisPlayers.value, competitorId];
      const response = await addFavoriteTennisPlayers([`${competitorId}`]);
      if (response.status !== "success") {
        throw new Error("Failed to add player to favorites");
      }
    } catch (error) {
      console.error("Failed to sync additions:", error);
    }
  };

  const removeFavoriteTennisPlayer = async (competitorId: number | string) => {
    try {
      favoriteTennisPlayers.value = favoriteTennisPlayers.value.filter(
        (id) => id !== `${competitorId}`
      );
      const { status } = await removeFavoriteTennisPlayers([`${competitorId}`]);
      if (status !== 204) {
        throw new Error("Failed to remove player to favorites");
      }
    } catch (error) {
      console.error("Failed to sync removals:", error);
    }
  };

  const getFavoriteTennisPlayersQuery = async () => {
    if (userStore.cuid) {
      favoriteTennisPlayers.value = [];
    } else {
      const job = await getFavoriteTennisPlayers();
      favoriteTennisPlayers.value = job;
    }
  };

  getFavoriteTennisPlayersQuery();

  return {
    favoriteTennisPlayers,
    addFavoriteTennisPlayer,
    removeFavoriteTennisPlayer,
  };
};
